import classNames from 'classnames'

import styles from './DraggableCardsList.module.scss'

const LeftArrow = () => (
  <svg
    viewBox='0 0 36 80'
    width='36'
    height='80'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    className={styles.arrowSVG}
  >
    <path d='M36 0 L0 40 L36 80' className={styles.arrowPath} />
  </svg>
)

const RightArrow = () => (
  <svg
    viewBox='0 0 36 80'
    width='36'
    height='80'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    className={classNames(styles.arrowSVG, styles.isRight)}
  >
    <path d='M0 0 L36 40 L0 80' className={styles.arrowPath} />
  </svg>
)

export const Illustration = (): JSX.Element => {
  return (
    <div className={styles.illustration}>
      <LeftArrow />
      <LeftArrow />
      <RightArrow />
      <RightArrow />
    </div>
  )
}
