import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import dynamic from 'next/dynamic'
import {useEffect, useRef} from 'react'
import {render} from 'storyblok-rich-text-react-renderer'

import {THeroWithPartnerLogo} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './HeroWithPartnerLogo.module.scss'

gsap.registerPlugin(ScrollTrigger)

const Line3D = dynamic(() => import('@/assets/Line3D'), {ssr: false})
const Sphere3D = dynamic(() => import('@/assets/Sphere3D'), {ssr: false})

const HeroWithPartnerLogo = ({
  heading,
  paragraph,
  image,
  logo,
}: THeroWithPartnerLogo): JSX.Element => {
  const sectionRef = useRef<HTMLElement>(null)
  const badgeRef = useRef<HTMLImageElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const line3dRef = useRef<HTMLDivElement>(null)
  const sphere3dRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (sectionRef.current) {
      if (line3dRef.current) {
        gsap.to(line3dRef.current, {
          yPercent: 20,
          scrollTrigger: {
            start: 'top top',
            end: 'bottom center',
            scrub: 0.5,
            trigger: sectionRef.current,
          },
        })
      }

      if (badgeRef.current) {
        gsap.to(badgeRef.current, {
          yPercent: 30,
          scrollTrigger: {
            start: 'top top',
            end: 'bottom center',
            scrub: 1,
            trigger: sectionRef.current,
          },
        })
      }

      if (imageRef.current) {
        gsap.to(imageRef.current, {
          yPercent: -20,
          scrollTrigger: {
            start: 'top top',
            end: 'bottom center',
            scrub: 2,
            trigger: sectionRef.current,
          },
        })
      }

      if (sphere3dRef.current) {
        gsap.to(sphere3dRef.current, {
          yPercent: 20,
          scrollTrigger: {
            start: 'top top',
            end: 'bottom center',
            scrub: 1,
            trigger: sectionRef.current,
          },
        })
      }
    }
  }, [])

  return (
    <section className={styles.wrapper} ref={sectionRef}>
      <div className={styles.section}>
        {heading && (
          <div className={styles.headingContainer}>
            <div className={styles.line3D}>
              <div ref={line3dRef}>
                <Line3D />
              </div>
            </div>
            <h1 className={styles.heading}>{render(heading, unwrapRenderParagraph)}</h1>
          </div>
        )}

        <div className={styles.content}>
          {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
        </div>

        {image && (
          <div className={styles.imageContainer}>
            <StoryblokImage
              className={styles.image}
              ref={imageRef}
              src={image.filename}
              alt={image.alt}
            />

            <div className={styles.sphere3D}>
              <div ref={sphere3dRef}>
                <Sphere3D />
              </div>
            </div>

            {logo && (
              <div className={styles.badge} ref={badgeRef}>
                <StoryblokImage className={styles.logo} src={logo.filename} alt={logo.alt} />
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default HeroWithPartnerLogo
