import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgHeroComplexArrowCross({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={280}
      height={280}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M176.875 242H242v-65.125h-27.25v18.605l-48.991-48.987-19.266 19.266 48.991 48.991h-18.609V242zm2-2v-23.25h21.437l-50.991-50.991 16.438-16.437 50.991 50.986v-21.433H240V240h-61.125zm-75.75 2v-27.25H84.52l48.987-48.991-19.266-19.266-48.991 48.991v-18.609H38V242h65.125zM40 240v-61.125h23.25v21.437l50.991-50.99 16.437 16.437-50.986 50.991h21.433V240H40zm125.76-106.493l48.991-48.991v18.609H242V38h-65.125v27.25h18.609l-48.991 48.991 19.266 19.266zm-51.518 0l19.266-19.266-48.99-48.991h18.608V38H38v65.125h27.25v-18.61l48.991 48.992zm35.081-19.266l50.991-50.99h-21.438V40H240v61.124h-23.25V79.688l-50.991 50.99-16.437-16.437zm-35.081 16.437l-50.99-50.99v21.437H40V40h61.124v23.25H79.688l50.99 50.991-16.437 16.437z'
        fill='url(#hero-complex-arrow-cross_svg__a)'
      />
      <defs>
        <linearGradient
          id='hero-complex-arrow-cross_svg__a'
          x1={242}
          y1={38}
          x2={-4.062}
          y2={147.361}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#77CEF2' />
          <stop offset={1} stopColor='#9479F7' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgHeroComplexArrowCross
