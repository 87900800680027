import gsap, {Power4} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import {random} from 'lodash'
import {useEffect, useMemo, useRef} from 'react'

import {TCtaCentered} from '@/common/types'
import Button from '@/components/Button'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './CtaCentered.module.scss'

gsap.registerPlugin(ScrollTrigger)

const CtaCentered = ({
  heading,
  paragraph,
  btnLabel,
  btnLink,
  bgImage,
}: TCtaCentered): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null)
  const timeline = useMemo(() => gsap.timeline({default: {ease: Power4.easeInOut}}), [])

  useEffect(() => {
    if (svgRef.current) {
      ScrollTrigger.create({
        animation: timeline,
        trigger: svgRef.current,
        start: '20% bottom',
      })

      const elements = Array.from(svgRef.current.children)
      elements.forEach((el, index) => {
        timeline.to(
          el,
          {
            r: random(5.2, 5.8) * (index + 1),
          },
          '-=0.7'
        )
      })
    }
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.circles}>
        <svg
          className={styles.circle}
          viewBox='0 0 100 100'
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='xMidYMid slice'
          ref={svgRef}
        >
          {Array.from(Array(10)).map((_, index) => (
            <circle key={index} cx='50' cy='50' r={0} />
          ))}
        </svg>
      </div>

      {bgImage && (
        <StoryblokImage className={styles.bgImage} src={bgImage.filename} alt={bgImage.alt} />
      )}

      <div className={styles.container}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
        {btnLabel && btnLink && (
          <div className={styles.button}>
            <Button link={btnLink} label={btnLabel} isSecondary />
          </div>
        )}
      </div>
    </section>
  )
}

export default CtaCentered
