import {useEffect, useRef, useState} from 'react'

import {TChannels, TChannelSelected as TChannelSelectedComponent} from '@/common/types'
import ButtonSecondary from '@/components/ButtonSecondary'
import ButtonTertiary from '@/components/ButtonTertiary'
import Card from '@/components/Card'
import ChannelSelectedComponent from '@/components/ChannelSelected'

import styles from './Channels.module.scss'

type TActiveChannel = {
  item: TChannelSelectedComponent
  order: number
  cardSize: 'isSmall' | 'isLarge'
}

type TChannelSelected = TActiveChannel & {isVisible: boolean; onClose: () => void}

const ChannelSelected = ({isVisible, item, order, onClose}: TChannelSelected) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({behavior: 'smooth'})
    }
  })

  if (!isVisible) return null

  return (
    <div ref={containerRef} className={styles.channelSelected} style={{order}}>
      <ChannelSelectedComponent {...item} onClick={onClose} />
    </div>
  )
}

const Channels = ({
  heading,
  paragraph,
  cardLarge,
  cardSmall,
  btnLabel,
  btnLabelSecondary,
  btnLink,
  isMarketplace,
  defaultMaxSmallItems = 10,
}: TChannels): JSX.Element => {
  const [activeItem, setActiveItem] = useState<TActiveChannel>()
  const [seeAll, setSeeAll] = useState(false)

  function handleClose() {
    setActiveItem(undefined)
  }

  return (
    <section className={styles.section}>
      {heading && (
        <h1 className={isMarketplace ? styles.headingMarketplace : styles.headingAcquisition}>
          {heading}
        </h1>
      )}

      {paragraph && <p className={styles.paragraph}>{paragraph}</p>}

      {btnLabel && btnLink && (
        <div className={styles.buttonSecondary}>
          {isMarketplace ? (
            <ButtonSecondary label={btnLabel} link={btnLink} isMarketplace />
          ) : (
            <ButtonSecondary label={btnLabel} link={btnLink} />
          )}
        </div>
      )}

      <div className={styles.cards}>
        <article className={styles.cardsLarge}>
          {cardLarge &&
            cardLarge.map((props, index) => (
              <div
                onClick={() => setActiveItem({item: props, order: index, cardSize: 'isLarge'})}
                aria-hidden='true'
                key={props._uid}
                className={styles.cardLarge}
                style={{order: index}}
              >
                <Card {...props} isLarge />
              </div>
            ))}
          {activeItem && (
            <ChannelSelected
              onClose={handleClose}
              isVisible={activeItem.cardSize === 'isLarge'}
              {...activeItem}
            />
          )}
        </article>

        <article className={styles.cardsSmall}>
          {cardSmall &&
            cardSmall.map((props, index) => {
              if (!seeAll && index >= defaultMaxSmallItems) return null

              return (
                <div
                  onClick={() => setActiveItem({item: props, order: index, cardSize: 'isSmall'})}
                  aria-hidden='true'
                  key={props._uid}
                  className={styles.cardSmall}
                  style={{order: index}}
                >
                  <Card {...props} />
                </div>
              )
            })}
          {activeItem && (
            <ChannelSelected
              onClose={handleClose}
              isVisible={activeItem.cardSize === 'isSmall'}
              {...activeItem}
            />
          )}
        </article>
      </div>

      {btnLabelSecondary && !seeAll && (
        <ButtonTertiary
          className={styles.buttonTertiary}
          label={btnLabelSecondary}
          onClick={() => setSeeAll(true)}
        />
      )}
    </section>
  )
}

export default Channels
