import classNames from 'classnames'

import {ChevronRightXs} from '@/assets/specify'
import {TChannelsV2Card} from '@/common/types'
import ButtonTertiary from '@/components/ButtonTertiary'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './ChannelsV2Card.module.scss'

const ChannelsV2Card = ({
  isLarge,
  logo,
  color,
  heading,
  description,
  callToAction,
  onClick = () => undefined,
  order,
}: TChannelsV2Card): JSX.Element => (
  <div
    className={classNames(styles.card, {[styles.isLarge]: isLarge})}
    style={{order}}
    onClick={onClick}
    aria-hidden='true'
  >
    <div className={styles.background} style={{backgroundColor: color}}>
      {logo && <StoryblokImage className={styles.logo} src={logo.filename} alt={logo.alt} />}
    </div>
    {heading && (
      <div className={styles.header}>
        <h4 className={styles.heading}>{heading}</h4>
        {!isLarge && (
          <div className={styles.icon}>
            <ChevronRightXs />
          </div>
        )}
      </div>
    )}
    {isLarge && description && <p className={styles.description}>{description}</p>}
    {isLarge && callToAction && (
      <ButtonTertiary className={styles.callToAction} label={callToAction} onClick={onClick} />
    )}
  </div>
)

export default ChannelsV2Card
