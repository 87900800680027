import {Popover, Transition} from '@headlessui/react'
import classNames from 'classnames'
import {Fragment, useRef} from 'react'

import {componentTypes} from '@/common/enums'
import {TNavigationItemRich} from '@/common/types'
import Link from '@/components/Link'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './NavigationDesktop.module.scss'

type PopoverNavigationProps = {
  item: TNavigationItemRich
  index: number
}

const PopoverNavigation = ({item, index}: PopoverNavigationProps): JSX.Element => {
  const popoverButtonsRef = useRef<(HTMLButtonElement | null)[]>([])

  const handleEnter = (isOpen: boolean, index: number) => {
    const button = popoverButtonsRef.current && popoverButtonsRef.current[index]
    if (!isOpen && button) button.click()
  }

  const handleLeave = (isOpen: boolean, index: number) => {
    const button = popoverButtonsRef.current && popoverButtonsRef.current[index]
    if (isOpen && button) button.click()
  }

  return (
    <Popover as={Fragment}>
      {({open}) => (
        <div
          className={classNames(styles.popover, {
            [styles[item.theme || '']]: item.theme,
            [styles.isOpen]: open,
          })}
          onMouseEnter={() => handleEnter(open, index)}
          onMouseLeave={() => handleLeave(open, index)}
        >
          <Popover.Button
            className={styles.item}
            ref={(e) => (popoverButtonsRef.current[index] = e)}
          >
            {item.label}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter={styles.transitionEnter}
            enterFrom={styles.transitionEnterFrom}
            enterTo={styles.transitionEnterTo}
            leave={styles.transitionLeave}
            leaveFrom={styles.transitionLeaveFrom}
            leaveTo={styles.transitionLeaveTo}
          >
            <Popover.Panel className={styles.panelWrapper}>
              <div className={styles.panel}>
                {(item.items || []).map((item, index, {length}) => {
                  if (index <= 2) {
                    const span = [[12], [6, 6], [3, 6, 3]][length - 1][index]
                    if (item.component === componentTypes.NAVIGATION_ITEM_RICH_LIST) {
                      return (
                        <div
                          key={item._uid}
                          className={styles.column}
                          style={{gridColumn: `span ${span}`}}
                        >
                          {item.heading ? <p className={styles.heading}>{item.heading}</p> : null}
                          <div
                            className={classNames(styles.list, {
                              [styles.isLarge]: item.size === 'large',
                            })}
                          >
                            {(item.items || []).map((item) => {
                              if (
                                item.component === componentTypes.NAVIGATION_ITEM_RICH_LIST_ITEM
                              ) {
                                return (
                                  <Link key={item._uid} className={styles.link} link={item.link}>
                                    {item.icon && item.icon.filename && (
                                      <span className={styles.icon} aria-hidden>
                                        <StoryblokImage src={item.icon.filename} alt='' />
                                      </span>
                                    )}
                                    {item.label}
                                  </Link>
                                )
                              }
                              return null
                            })}
                          </div>
                        </div>
                      )
                    }
                  }
                  return null
                })}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  )
}

export default PopoverNavigation
