import gsap, {Power2} from 'gsap'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useSwipeable} from 'react-swipeable'

import {breakpoints, componentTypes} from '@/common/enums'
import {TCardSlider} from '@/common/types'
import Button from '@/components/Button'
import DynamicComponent from '@/components/DynamicComponent'
import SlidersButton from '@/components/SlidersButton'
import {useViewport} from '@/hooks/ViewportContext'

import styles from './CardSlider.module.scss'
import {convertResourceItemToCardSliderItem} from './CardSliderHelpers'

const CardSlider = ({
  heading,
  paragraph,
  cards = [],
  btnLabel,
  btnLink,
  locale,
  resourceCategories,
}: TCardSlider): JSX.Element => {
  const [active, setActive] = useState(0)
  const [length, setLength] = useState(cards.length)
  const {width} = useViewport()
  const itemsRef = useRef(null) as React.MutableRefObject<HTMLDivElement | null>
  const timeline = useMemo(() => gsap.timeline(), [])

  const onSwipe = useSwipeable({
    onSwipedLeft: () => handleClick('next'),
    onSwipedRight: () => handleClick('prev'),
    preventDefaultTouchmoveEvent: true,
  })

  const refPassthrough = (el: HTMLDivElement) => {
    onSwipe.ref(el)
    itemsRef.current = el
  }

  function triggerAnimation(direction: 'next' | 'prev'): void {
    if (itemsRef.current) {
      timeline.to(itemsRef.current, {
        x: `${direction === 'next' ? '-' : '+'}=100%`,
        ease: Power2.easeOut,
      })
    }
  }

  function handleClick(direction: 'next' | 'prev'): void {
    setActive((prev) => {
      let next: number

      if (direction === 'next') {
        next = prev === length - 1 ? prev : prev + 1
      } else {
        next = prev > 0 ? prev - 1 : prev
      }

      if (next !== prev) triggerAnimation(direction)

      return next
    })
  }

  useEffect(() => {
    if (width) {
      if (width >= breakpoints.LG) {
        setLength(cards.length - 2)
      } else if (width >= breakpoints.MD) {
        setLength(cards.length - 1)
      }
    }
  }, [width])

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}

        <div className={styles.buttons}>
          <div className={styles.button}>
            <SlidersButton
              arrow='left'
              onClick={() => handleClick('prev')}
              disabled={active === 0}
            />
          </div>
          <div className={styles.button}>
            <SlidersButton
              arrow='right'
              onClick={() => handleClick('next')}
              disabled={active === length - 1}
            />
          </div>
        </div>

        <div className={styles.items} {...onSwipe} ref={refPassthrough}>
          {cards.map((props) => {
            const updatedProps =
              props.component === componentTypes.RESOURCE_ITEM
                ? convertResourceItemToCardSliderItem({resourceCategories, locale, ...props})
                : props

            return (
              <div key={updatedProps._uid} className={styles.item}>
                <DynamicComponent {...updatedProps} />
              </div>
            )
          })}
        </div>

        {btnLabel && btnLink && (
          <div className={styles.cta}>
            <Button label={btnLabel} link={btnLink} />
          </div>
        )}
      </div>
    </section>
  )
}

export default CardSlider
