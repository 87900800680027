import classNames from 'classnames'

import {ChevronRightXs} from '@/assets/specify'
import {TButtonTertiary} from '@/common/types'
import Link from '@/components/Link'

import styles from './ButtonTertiary.module.scss'

const ButtonTertiary = ({
  className,
  label,
  link,
  onClick = () => undefined,
  ...props
}: TButtonTertiary): JSX.Element => {
  const sharedClassName = classNames(className, styles.button, {
    [styles.acquisition]: props.isMarketplace,
    [styles.marketplace]: props.isAcquisition,
    [styles.isDark]: props.isDark,
  })

  if (link && label) {
    return (
      <Link link={link} className={sharedClassName}>
        {label}
        <span className={styles.icon}>
          <ChevronRightXs className={styles.arrow} />
        </span>
      </Link>
    )
  }

  return (
    <>
      {label && (
        <button {...props} className={sharedClassName} onClick={onClick}>
          {label}
          <span className={styles.icon}>
            <ChevronRightXs className={styles.arrow} />
          </span>
        </button>
      )}
    </>
  )
}

export default ButtonTertiary
