import classNames from 'classnames'
import {useRef, useState} from 'react'
import Select from 'react-select'
import {MARK_BOLD, MARK_ITALIC, render} from 'storyblok-rich-text-react-renderer'

import {componentTypes} from '@/common/enums'
import {TFeatures} from '@/common/types'
import {notUndefined} from '@/common/utils'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './Features.module.scss'

const Features = ({heading, paragraph, caption, items = []}: TFeatures): JSX.Element => {
  const imageRef = useRef<HTMLDivElement>(null)

  const defaultItem = items.findIndex(
    (i) => i.component === componentTypes.FEATURES_ITEM && i.isDefault
  )
  const defaultIndex = defaultItem > -1 ? defaultItem : 0
  const [activeItem, setActiveItem] = useState(defaultIndex)

  const options = items
    .map((item, index) => {
      if (item.component !== componentTypes.FEATURES_ITEM) return
      return {
        label: item.label,
        value: index,
      }
    })
    .filter(notUndefined)

  const onChange = (e: (typeof options)[number] | null) => {
    if (e) setActiveItem(e.value)

    setTimeout(() => {
      if (imageRef.current) {
        imageRef.current.scrollIntoView({behavior: 'smooth'})
      }
    }, 50)
  }

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        <h2 className={styles.heading}>{heading}</h2>
        <div className={styles.paragraph}>
          {render(paragraph, {
            markResolvers: {
              // eslint-disable-next-line react/display-name
              [MARK_BOLD]: (children: string) => <b className={styles.bold}>{children}</b>,
              // eslint-disable-next-line react/display-name
              [MARK_ITALIC]: () => (
                <Select
                  isSearchable={false}
                  className={styles.select}
                  classNamePrefix='react-select'
                  components={{IndicatorSeparator: () => null}}
                  options={options}
                  onChange={onChange}
                  defaultValue={options[defaultIndex]}
                />
              ),
            },
          })}
        </div>
        <p className={styles.caption}>{caption}</p>
      </div>
      <div className={styles.images} ref={imageRef}>
        {items.map((item, index) => {
          if (item.component !== componentTypes.FEATURES_ITEM) return null
          if (!item.image || !item.image.filename) return null
          return (
            <StoryblokImage
              key={item._uid}
              className={classNames(styles.image, {[styles.isActive]: index === activeItem})}
              alt={item.image.alt}
              src={item.image.filename}
              sizes={{lg: 50}}
              style={{visibility: index === activeItem ? 'visible' : 'hidden'}}
            />
          )
        })}
      </div>
    </section>
  )
}

export default Features
