import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgCircles({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={657}
      height={657}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <circle
        cx={328.501}
        cy={328.5}
        r={67.769}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
      <circle
        cx={328.502}
        cy={328.5}
        r={103.008}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
      <circle
        cx={328.502}
        cy={328.5}
        r={135.537}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
      <circle
        cx={328.5}
        cy={328.5}
        r={185.686}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
      <circle
        cx={328.499}
        cy={328.5}
        r={216.86}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
      <circle
        cx={328.5}
        cy={328.5}
        r={262.942}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
      <circle
        cx={328.5}
        cy={328.5}
        r={328}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
      <circle
        cx={328.502}
        cy={328.5}
        r={25.752}
        stroke='#B5B4D2'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeDasharray='1.63 3.26'
      />
    </svg>
  )
}

export default SvgCircles
