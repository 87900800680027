import classNames from 'classnames'
import gsap from 'gsap'
import {random} from 'lodash'
import React, {useEffect, useRef} from 'react'
import {render} from 'storyblok-rich-text-react-renderer'

import {
  DiscoverOurTeamBadgeBlue,
  DiscoverOurTeamBadgeWhite,
  HeroComplexArrowCross,
  HeroComplexArrowRound,
} from '@/assets/specify'
import {componentTypes} from '@/common/enums'
import {THeroComplex} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import DynamicComponent from '@/components/DynamicComponent'

import Link from '../Link'
import styles from './HeroComplex.module.scss'

const HeroComplex = ({
  heading,
  paragraph,
  secondParagraph,
  images = [],
  isAlternative,
  badgeLink,
}: THeroComplex): JSX.Element => {
  const picturesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (picturesRef.current) {
      const elements = Array.from(picturesRef.current.querySelectorAll(`.${styles.picture}`))
      elements.forEach((el) => {
        gsap.to(el, {
          y: `-=${random(10, 30)}%`,
          scrollTrigger: {
            start: '70% bottom',
            end: 'center top',
            scrub: random(0.5, 1.5),
            trigger: picturesRef.current,
          },
        })
      })
    }
  }, [])

  return (
    <section className={classNames(styles.section, {[styles.isAlternative]: isAlternative})}>
      <div className={styles.container}>
        <HeroComplexArrowRound className={styles.arrowRound} />
        <HeroComplexArrowCross className={styles.arrowCross} />
        {heading && <h1 className={styles.heading}>{render(heading, unwrapRenderParagraph)}</h1>}
        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
        {images.length > 0 && (
          <div className={styles.pictures} ref={picturesRef}>
            {images.map((props, index) => {
              if (props.component !== componentTypes.IMAGE) return null
              return (
                <div
                  key={props._uid}
                  className={classNames(styles.picture, styles[`picture-${index}`])}
                >
                  <DynamicComponent
                    {...props}
                    wrapperClassName={styles.image}
                    withLoader={true}
                    sizes={{sm: 75, lg: 45}}
                  />
                </div>
              )
            })}
            {badgeLink && (
              <Link link={badgeLink} className={styles.badge}>
                {isAlternative ? (
                  <DiscoverOurTeamBadgeWhite title='Discover our team' />
                ) : (
                  <DiscoverOurTeamBadgeBlue title='Discover our team' />
                )}
              </Link>
            )}
          </div>
        )}
        {secondParagraph && <p className={styles.secondParagraph}>{secondParagraph}</p>}
      </div>
    </section>
  )
}

export default HeroComplex
