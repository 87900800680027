import classNames from 'classnames'
import {useEffect, useRef, useState} from 'react'

import {TChannelsV2Cards, TChannelsV2Channel} from '@/common/types'
import ChannelSelectedComponent from '@/components/ChannelSelected'

import Card from '../Card'
import styles from './ChannelsV2Cards.module.scss'

const ChannelsV2 = ({
  heading,
  channels,
  isLarge,
  showMoreLabel,
  websiteLabel,
  findOutMoreLabel,
}: TChannelsV2Cards): JSX.Element => {
  const [activeItem, setActiveItem] = useState<{channel: TChannelsV2Channel; order: number}>()
  const activeItemContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (activeItemContainerRef.current) {
      activeItemContainerRef.current.scrollIntoView({behavior: 'smooth'})
    }
  })

  if (!channels || channels.length === 0) {
    return <div />
  }

  return (
    <>
      {heading && (
        <h3 className={classNames(styles.heading, {[styles.isLarge]: isLarge})}>{heading}</h3>
      )}
      <div className={classNames(styles.cards, {[styles.isLarge]: isLarge})}>
        {channels.map((props, index) => (
          <Card
            key={props.uuid}
            isLarge={isLarge}
            heading={props.name}
            description={props.content.description}
            logo={
              props.content.isHighlighted &&
              props.content.highlightLogo &&
              props.content.highlightLogo.filename
                ? props.content.highlightLogo
                : props.content.logo
            }
            color={props.content.isHighlighted ? props.content.highlightColor : undefined}
            callToAction={showMoreLabel}
            onClick={() => setActiveItem({channel: props, order: index})}
            order={index}
          />
        ))}
        {activeItem && (
          <div
            ref={activeItemContainerRef}
            className={styles.channelSelected}
            style={{order: activeItem.order}}
          >
            <ChannelSelectedComponent
              {...activeItem.channel}
              logo={activeItem.channel.content.logo}
              heading={activeItem.channel.name}
              countries={activeItem.channel.content.countries}
              paragraph={activeItem.channel.content.description}
              list={activeItem.channel.content.features}
              onClick={() => setActiveItem(undefined)}
              btnLabel={websiteLabel}
              btnLink={activeItem.channel.content.website}
              btnLabelSecondary={findOutMoreLabel}
              btnLinkSecondary={activeItem.channel.content.findOutMore}
              version={2}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ChannelsV2
