import {TChannelSelected} from '@/common/types'

export function getTranslatedList(
  path: string,
  list: TChannelSelected['list'] = []
): (string | undefined)[] {
  return list.map((item) => {
    if (path.startsWith('/fr/')) {
      switch (item) {
        case 'RC':
          return 'Centralisation des commandes'
        case 'S&P':
          return "Données d'inventaire et de prix"
        case 'CP':
          return 'Création de produits'
        case 'TP':
          return 'Tracking des performances'
      }
    } else if (path.startsWith('/es/')) {
      switch (item) {
        case 'RC':
          return 'Recuperación de pedidos'
        case 'S&P':
          return 'Stock y precios'
        case 'CP':
          return 'Creación de productos'
        case 'TP':
          return 'Seguimiento de la actividad'
      }
    } else {
      switch (item) {
        case 'RC':
          return 'Order import'
        case 'S&P':
          return 'Pricing and inventory managment'
        case 'CP':
          return 'Product synchronisation'
        case 'TP':
          return 'Analytics and report'
      }
    }
  })
}
