import {useRouter} from 'next/router'

import {CloseS} from '@/assets/specify'
import {CheckS} from '@/assets/specify'
import {translationNamespaces} from '@/common/enums'
import {TChannelSelected} from '@/common/types'
import {isValidLink} from '@/common/utils'
import ButtonSecondary from '@/components/ButtonSecondary'
import ButtonTertiary from '@/components/ButtonTertiary'
import StoryblokImage from '@/components/StoryblokImage'
import {useTranslations} from '@/hooks/useTranslations'

import styles from './ChannelSelected.module.scss'
import {getTranslatedList} from './ChannelSelectedHelpers'

const ChannelSelected = ({
  logo,
  heading,
  countries,
  paragraph,
  list = [],
  btnLabel,
  btnLabelSecondary,
  btnLink,
  btnLinkSecondary,
  onClick = () => undefined,
  version,
}: TChannelSelected): JSX.Element => {
  const {asPath} = useRouter()
  const translatedList = version === 2 ? list : getTranslatedList(asPath, list)
  const {t, getName} = useTranslations()

  return (
    <section className={styles.section}>
      <div className={styles.header}>
        <div className={styles.logo}>
          {logo && (
            <StoryblokImage
              className={styles.logoImage}
              src={logo.filename}
              alt=''
              sizes={{sm: 25, lg: 15}}
            />
          )}
        </div>
        <CloseS className={styles.closeButton} onClick={onClick} />
      </div>

      {heading && <h2 className={styles.heading}>{heading}</h2>}

      <div className={styles.countries}>
        {countries &&
          countries.map((country, i) => {
            const ISO3166 =
              version === 2
                ? getName(translationNamespaces.CHANNELS_V2_COUNTRIES, country)
                : country

            return <span key={i} className={`fi fi-${ISO3166} ${styles.country}`} />
          })}
      </div>

      {paragraph && <p className={styles.paragraph}>{paragraph}</p>}

      <div className={styles.list}>
        {translatedList.map((item, i) => (
          <span key={i} className={styles.listItem}>
            <CheckS className={styles.checkmark} />{' '}
            {version === 2 && item ? t(translationNamespaces.CHANNELS_V2_FEATURES, item) : item}
          </span>
        ))}
      </div>

      <div className={styles.buttons}>
        {btnLabel && isValidLink(btnLink) && (
          <ButtonSecondary
            className={styles.buttonSecondary}
            hasIcon
            hasRightIcon
            label={btnLabel}
            link={btnLink}
          />
        )}

        {btnLabelSecondary && isValidLink(btnLinkSecondary) && (
          <ButtonTertiary
            className={styles.buttonTertiary}
            isAcquisition
            label={btnLabelSecondary}
            link={btnLinkSecondary}
          />
        )}
      </div>
    </section>
  )
}

export default ChannelSelected
