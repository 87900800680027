import Marquee from 'react-fast-marquee'

import {componentTypes} from '@/common/enums'
import {TLogos} from '@/common/types'
import Image from '@/components/Image'

import styles from './Logos.module.scss'

const Logos = ({images}: TLogos): JSX.Element => {
  return (
    <section className={styles.section}>
      <Marquee speed={80} pauseOnHover={true} gradient={false}>
        {images.map((props) => {
          if (props.component !== componentTypes.IMAGE) {
            return null
          }

          return (
            <div key={props._uid} className={styles.item}>
              <Image
                _uid={props._uid}
                component={props.component}
                image={props.image}
                wrapperClassName={styles.imageWrapper}
                className={styles.image}
              />
            </div>
          )
        })}
      </Marquee>
    </section>
  )
}

export default Logos
