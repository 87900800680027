import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgArrowRightXs({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={12}
      height={12}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M8.172 3.16a.541.541 0 00-.768 0 .547.547 0 000 .771l1.517 1.524H1.543a.544.544 0 000 1.09h7.378L7.404 8.07a.547.547 0 000 .771.541.541 0 00.768 0l2.444-2.454L11 6l-.384-.386L8.172 3.16z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgArrowRightXs
