export type TContext = {
  length: number
  timeline: gsap.core.Timeline
  setActive: (index: number) => void
}

export const speed = 8 // in seconds

function getLabel(index?: number): string {
  return `item-${index || ''}`
}

export function buildTimeline(htmlCollection: HTMLCollection, context: TContext): void {
  const elements = Array.from(htmlCollection)
  elements.forEach((el, index) => {
    const {timeline, setActive} = context
    const duration = 0.25
    const repeatDelay = speed - 2 * duration

    timeline
      .fromTo(
        el,
        {autoAlpha: 0},
        {
          autoAlpha: 1,
          duration,
          repeatDelay,
          yoyo: true,
          repeat: 1,
          onStart: () => setActive(index),
        },
        getLabel(index)
      )
      .fromTo(
        el.querySelectorAll('p'),
        {y: 15},
        {
          y: 0,
          duration,
        },
        getLabel(index)
      )
  })
}

export function handleClick(way: 'prev' | 'next', context: TContext): void {
  const {timeline, length} = context

  if (way === 'next') {
    const nextLabel = timeline.nextLabel() ?? getLabel(0)
    timeline.currentLabel(nextLabel)
  } else {
    const currentIndex = parseInt(timeline.currentLabel().replace(getLabel(), ''))
    const nextIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : length - 1
    const previousLabel = getLabel(nextIndex)
    timeline.currentLabel(previousLabel)
  }
}
