import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgChevronRightXs({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={12}
      height={12}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.646 10.354a.5.5 0 010-.708L7.293 6 3.646 2.354a.5.5 0 11.708-.708L8.707 6l-4.353 4.354a.5.5 0 01-.708 0z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgChevronRightXs
