import {TButtonStoryblok} from '@/common/types'
import Button from '@/components/Button'
import ButtonSecondary from '@/components/ButtonSecondary'

const ButtonStoryblok = ({
  className,
  label,
  link,
  theme = 'default',
  ...props
}: TButtonStoryblok): JSX.Element => {
  if (!theme || theme === 'default') {
    return <Button className={className} label={label} link={link} {...props} />
  }

  return (
    <ButtonSecondary
      className={className}
      label={label}
      link={link}
      isMarketplace={theme === 'marketplace'}
      {...props}
    />
  )
}

export default ButtonStoryblok
