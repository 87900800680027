import classNames from 'classnames'
import gsap, {Linear} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {ReactNode, useEffect, useRef} from 'react'

// import ResizeObserver from 'resize-observer-polyfill'
import styles from './MarqueeV2.module.scss'

gsap.registerPlugin(ScrollTrigger)

export interface MarqueeV2 {
  children: ReactNode
  className?: string
  speed?: number
  maxVelocity?: number
  velocityFactor?: number
  accellerationDuration?: number
  reverseOnScrollUp?: boolean
  isReversed?: boolean
}

type scrollTriggerType = {
  kill: () => void
}

const MarqueeV2 = ({
  children,
  className,
  speed = 75,
  maxVelocity = 1000,
  velocityFactor = 1,
  accellerationDuration = 0.2,
  reverseOnScrollUp = true,
  isReversed = false,
}: MarqueeV2): JSX.Element => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const tweenRef = useRef<GSAPTween>()
  const scrollTriggerRef = useRef<scrollTriggerType>()

  useEffect(() => {
    const section = sectionRef.current
    const container = containerRef.current
    const child = container ? container.firstElementChild : null

    // Refresh animation if content size change
    // Triggered on init
    // const resizeObserver = new ResizeObserver(() => {
    //   // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
    //   // https://stackoverflow.com/a/58701523
    //   window.requestAnimationFrame(() => {
    //     initAnimation()
    //   })
    // })

    // if (container) {
    //   const firstElementChild = container.firstElementChild
    //   if (firstElementChild) resizeObserver.observe(firstElementChild)
    // }

    // if (section) {
    //   resizeObserver.observe(section)
    // }

    function initAnimation() {
      if (section && container && child) {
        clearAnimation()
        cloneContent(section, container, child as HTMLDivElement)

        const distance = child.clientWidth
        const time = distance / speed

        gsap.set(container.children, {
          flex: '0 0 auto',
          marginLeft: 0,
          marginRight: 0,
        })

        // Marquee Animation
        const tween = gsap.to(container.children, {
          repeat: -1,
          x: (isReversed ? '+' : '-') + distance,
          ease: Linear.easeNone,
          duration: time,
          // Prevent reverse for stopping
          // https://greensock.com/forums/topic/28078-reverse-repeat/
          onReverseComplete: function () {
            this.totalTime(time * 100 + this.rawTime())
            // include rawTime() to compensate for the tiny offset between frames (zero time drift)
            // https://greensock.com/forums/topic/30722-how-to-properly-reverse-a-repeating-hover-animation-on-mouseleave/#comment-153497
          },
        })

        // // Accelerate on scroll
        let lastUpdateTimestamp: number

        const scrollTrigger = ScrollTrigger.create({
          animation: tween,
          trigger: container,
          start: 'top bottom',
          end: 'bottom top',
          toggleActions: 'play pause resume pause',
          onUpdate(self) {
            const velocity = Math.abs(self.getVelocity())

            if (velocity > 200) {
              const speed = maxVelocity
                ? (velocity >= maxVelocity ? maxVelocity : velocity) * (velocityFactor / 100)
                : 1
              const direction = reverseOnScrollUp ? self.direction : 1
              const timestamp = Date.now()

              gsap.to(tween, {
                duration: accellerationDuration,
                ease: Linear.easeNone,
                timeScale: speed * direction,
                onStart() {
                  lastUpdateTimestamp = timestamp
                },
                onComplete() {
                  if (timestamp === lastUpdateTimestamp) {
                    gsap.to(tween, {
                      duration: accellerationDuration,
                      ease: Linear.easeNone,
                      timeScale: direction,
                    })
                  }
                },
              })
            }
          },
        })

        // Store tween & scrollTrigger
        tweenRef.current = tween
        scrollTriggerRef.current = scrollTrigger
      }
    }

    function cloneContent(
      section: HTMLDivElement,
      container: HTMLDivElement,
      child: HTMLDivElement
    ) {
      // Clear clones
      const clones = container.querySelectorAll('[data-is-clone="true"]')
      clones.forEach((el) => el.remove())

      // Get amount of clones needed
      const count = Math.ceil(section.clientWidth / child.clientWidth) + 1

      if (count !== Infinity) {
        // Create clones
        for (let i = 0; i < count; i++) {
          const clone = child.cloneNode(true) as HTMLElement
          clone.dataset.isClone = 'true'
          clone.ariaHidden = 'true'
          container.appendChild(clone)
        }
      }
    }

    function clearAnimation() {
      // if (tweenRef.current) tweenRef.current.progress(0).pause().kill()
      if (tweenRef.current) tweenRef.current.progress(0).pause().revert().kill()
      if (scrollTriggerRef.current) scrollTriggerRef.current.kill()
    }

    initAnimation()

    return () => {
      clearAnimation()
      // resizeObserver.disconnect()
      // window.removeEventListener('resize', initAnimation)
    }
  }, [])

  return (
    <div ref={sectionRef} className={classNames(styles.marquee, className)}>
      <div
        ref={containerRef}
        className={classNames(
          styles.container,
          {[styles.justend]: isReversed},
          {[styles.juststart]: !isReversed}
        )}
      >
        <div className={styles.inline}>{children}</div>
      </div>
    </div>
  )
}

export default MarqueeV2
