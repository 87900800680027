import classNames from 'classnames'
import gsap from 'gsap'
import React, {useEffect} from 'react'
import {MARK_ITALIC, render} from 'storyblok-rich-text-react-renderer'

import {componentTypes} from '@/common/enums'
import {THeroSimple} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import ButtonStoryblok from '@/components/ButtonStoryblok'
import BgCircle from '@/components/HeroSimple/BgCircle'
import BgClipPath from '@/components/HeroSimple/BgClipPath'
import BgCorner from '@/components/HeroSimple/BgCorner'
import StoryblokImage from '@/components/StoryblokImage'

import DynamicComponent from '../DynamicComponent'
import Marquee from '../Marquee'
import styles from './HeroSimple.module.scss'

const HeroSimple = ({
  heading,
  paragraph,
  image,
  buttons = [],
  theme,
  clipImage,
  logos = [],
  videoHevc,
  videoWebm,
}: THeroSimple): JSX.Element => {
  const hasVideo = videoHevc && videoHevc.filename && videoWebm && videoWebm.filename

  const isAcquisition = theme === 'acquisition'
  const isMarketplace = theme === 'marketplace'
  const isDefault = !isAcquisition && !isMarketplace

  const sharedClassName = classNames({
    [styles.acquisition]: isAcquisition,
    [styles.marketplace]: isMarketplace,
  })

  useEffect(() => {
    const timelines: gsap.core.Timeline[] = []
    const rollers = Array.from(document.querySelectorAll(`.${styles.roller}`))

    rollers.forEach((roller) => {
      const timeline = gsap.timeline({repeat: -1})
      const items = Array.from(roller.querySelectorAll(`.${styles.item}`))
      items.forEach((item) => {
        timeline.fromTo(
          item,
          {yPercent: -90, opacity: 0},
          {yPercent: -50, opacity: 1, duration: 0.3}
        )
        timeline.to(item, {
          opacity: 0,
          yPercent: -20,
          duration: 0.3,
          delay: 0.8,
        })
      })

      timelines.push(timeline)
    })

    return () => {
      timelines.forEach((tl) => tl.kill())
    }
  }, [])

  return (
    <section className={classNames(styles.wrapper, sharedClassName)}>
      <div className={styles.background}>
        {!clipImage && (isAcquisition || isMarketplace) && (
          <div className={styles.bgCircleWrapper}>
            <div className={styles.bgCircleContainer}>
              <BgCircle className={classNames(styles.bgCircle, sharedClassName)} />
              <div className={classNames(styles.bgCircleFill, sharedClassName)} />
            </div>
          </div>
        )}
        {clipImage && image && image.filename && (
          <div className={styles.bgCircleWrapper}>
            <div className={styles.bgCircleContainer}>
              <BgClipPath className={styles.clipPath} />
              <div className={styles.clipContainer}>
                <StoryblokImage
                  className={styles.imageClipped}
                  src={image.filename}
                  alt=''
                  loading='eager'
                  sizes={{lg: 50}}
                />
              </div>
            </div>
          </div>
        )}
        <BgCorner className={classNames(styles.bgCorner, sharedClassName)} />
      </div>

      <div className={classNames(styles.section, sharedClassName)}>
        {!hasVideo && !clipImage && image && image.filename && (
          <StoryblokImage
            className={styles.image}
            src={image.filename}
            alt=''
            loading='eager'
            sizes={{lg: 50}}
          />
        )}

        {hasVideo && (
          <video className={styles.image} autoPlay loop muted playsInline preload='auto'>
            <source src={videoHevc.filename} type='video/mp4; codecs="hvc1"' />
            <source src={videoWebm.filename} type='video/webm' />
          </video>
        )}

        <article className={styles.article}>
          {heading && (
            <h1 className={classNames(styles.heading, {[styles.headingPrimary]: isDefault})}>
              {render(heading, {
                ...unwrapRenderParagraph,
                markResolvers: {
                  [MARK_ITALIC]: function Italic(children: React.ReactNode) {
                    if (typeof children === 'string') {
                      const words = children.split(' ')

                      const longestWord = words.reduce(
                        (longest, current) => (current.length > longest.length ? current : longest),
                        ''
                      )

                      return (
                        <>
                          <span className={styles.roller}>
                            <span aria-hidden className={styles.placeholder}>
                              {longestWord + ' '}
                            </span>
                            {words.map((word, index) => {
                              return (
                                <span key={index} className={styles.item}>
                                  {word}
                                </span>
                              )
                            })}
                          </span>
                          <br />
                        </>
                      )
                    }

                    return <>{children}</>
                  },
                },
              })}
            </h1>
          )}
          {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
          {logos.length > 0 && (
            <Marquee className={styles.marquee} scrollBack={false} velocity={0}>
              {logos.map((props) => {
                if (props.component !== componentTypes.IMAGE) return null
                return (
                  <DynamicComponent
                    key={props._uid}
                    {...props}
                    wrapperClassName={styles.logo}
                    className={styles.logoImage}
                  />
                )
              })}
            </Marquee>
          )}
          {buttons.length > 0 && (
            <div className={styles.buttons}>
              {buttons.map((props) => {
                if (props.component !== componentTypes.BUTTON_STORYBLOK) return null
                return (
                  <div key={props._uid}>
                    <ButtonStoryblok {...props} />
                  </div>
                )
              })}
            </div>
          )}
        </article>
      </div>
    </section>
  )
}

export default HeroSimple
