import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgCloseS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M17.635 15.973a1.16 1.16 0 010 1.69 1.16 1.16 0 01-.824.337c-.337 0-.636-.113-.86-.338l-3.932-3.94-3.97 3.94a1.16 1.16 0 01-.823.338c-.337 0-.636-.113-.86-.338a1.16 1.16 0 010-1.689l3.93-3.978-3.93-3.94a1.16 1.16 0 010-1.69 1.153 1.153 0 011.684 0l3.969 3.941 3.931-3.94a1.153 1.153 0 011.685 0c.487.45.487 1.238 0 1.689l-3.931 3.978 3.93 3.94z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgCloseS
