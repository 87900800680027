import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import dynamic from 'next/dynamic'
import {useEffect, useRef} from 'react'
import {render} from 'storyblok-rich-text-react-renderer'

import {componentTypes} from '@/common/enums'
import {THeroHome} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import ButtonStoryblok from '@/components/ButtonStoryblok'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './HeroHome.module.scss'

const Line3D = dynamic(() => import('@/assets/Line3D'), {ssr: false})
const Sphere3D = dynamic(() => import('@/assets/Sphere3D'), {ssr: false})

gsap.registerPlugin(ScrollTrigger)

const HeroHome = ({heading, paragraph, button = [], image}: THeroHome): JSX.Element => {
  const sectionRef = useRef<HTMLElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const line3dRef = useRef<HTMLDivElement>(null)
  const sphere3dRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (sectionRef.current) {
      if (line3dRef.current) {
        gsap.to(line3dRef.current, {
          y: '+=20%',
          scrollTrigger: {
            start: 'top top',
            end: 'bottom center',
            scrub: 0.5,
            trigger: sectionRef.current,
          },
        })
      }

      if (imageRef.current) {
        gsap.to(imageRef.current, {
          y: '-=20%',
          scrollTrigger: {
            start: 'top top',
            end: 'bottom center',
            scrub: 2,
            trigger: sectionRef.current,
          },
        })
      }

      if (sphere3dRef.current) {
        gsap.to(sphere3dRef.current, {
          y: '+=20%',
          scrollTrigger: {
            start: 'top top',
            end: 'bottom center',
            scrub: 1,
            trigger: sectionRef.current,
          },
        })
      }
    }
  }, [])

  return (
    <section className={styles.section} ref={sectionRef}>
      {heading && (
        <div className={styles.headingContainer}>
          <div className={styles.line3D} ref={line3dRef}>
            <Line3D />
          </div>
          <h1 className={styles.heading}>{render(heading, unwrapRenderParagraph)}</h1>
        </div>
      )}

      <div className={styles.content}>
        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
        {button.map((props, index) => {
          if (props.component !== componentTypes.BUTTON_STORYBLOK || index > 0) return null
          return (
            <div key={props._uid} className={styles.button}>
              <ButtonStoryblok {...props} theme='default' />
            </div>
          )
        })}
      </div>

      {image && (
        <div className={styles.imageContainer}>
          <StoryblokImage
            className={styles.image}
            ref={imageRef}
            src={image.filename}
            alt={image.alt}
          />
          <div className={styles.sphere3D} ref={sphere3dRef}>
            <Sphere3D />
          </div>
        </div>
      )}
    </section>
  )
}

export default HeroHome
