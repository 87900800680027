import {createContext, ReactNode, useContext} from 'react'

import {TGlobalLinks} from '@/common/types'

type Context = Record<string, string>

const Context = createContext<Context>({})

export const GlobalLinksProvider = ({
  lang,
  links = {},
  children,
}: {
  lang: string
  links?: TGlobalLinks
  children: ReactNode
}): JSX.Element => {
  const value: Context = {}
  for (const key in links) {
    if (links[key][lang]) {
      value[key] = links[key][lang]
    }
  }
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useGlobalLinks = (): Context => useContext(Context)
