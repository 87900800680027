import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgArrowDownS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M12.26 10.118a.7.7 0 000-1.054.876.876 0 00-1.157 0l-2.285 2.082v-8.4C8.818 2.333 8.452 2 8 2c-.452 0-.818.334-.818.745v8.401L4.897 9.064a.876.876 0 00-1.157 0 .7.7 0 000 1.054L8 14l4.26-3.882z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgArrowDownS
