import NextLink from 'next/link'

import {TLink} from '@/common/types'
import {useGlobalLinks} from '@/hooks/useGlobalLinks'

type TWrapper = {
  href?: string
  children: React.ReactNode
}

const Wrapper = ({href, children}: TWrapper) =>
  href ? <NextLink href={href}>{children}</NextLink> : <>{children}</>

const Link = ({label, link, children, className, style}: TLink): JSX.Element => {
  const globalLinks = useGlobalLinks()

  if ((label === '' && !children) || !link) return <div />

  let href = ''
  let linkOptions: Record<string, unknown> = {}

  if (link.linktype === 'story') {
    href = globalLinks[link.id]
  } else {
    if (link.url.startsWith('#')) {
      linkOptions = {
        href: link.url,
        onClick: () => {
          const anchor = document.querySelector(`#block-${link.url.replace('#', '')}`)
          if (anchor) {
            anchor.scrollIntoView({behavior: 'smooth'})
          }
        },
      }
    } else {
      linkOptions = {
        href: link.url,
        target: '_blank',
        rel: 'noreferrer',
      }
    }
  }

  return (
    <Wrapper href={link.linktype === 'story' ? href : undefined}>
      <a className={className} {...linkOptions} style={style}>
        {label} {children}
      </a>
    </Wrapper>
  )
}

export default Link
