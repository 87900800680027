import {TIndustryCard} from '@/common/types'
import Link from '@/components/Link'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './IndustryCard.module.scss'

const IndustryCard = ({heading, image, link}: TIndustryCard): JSX.Element => {
  if (!heading || !image || !link) return <div />

  return (
    <Link className={styles.card} link={link}>
      <StoryblokImage src={image.filename} alt={image.alt} className={styles.image} />
      <div className={styles.gradient} />
      <span className={styles.heading}>{heading}</span>
    </Link>
  )
}

export default IndustryCard
