import classNames from 'classnames'
import {gsap} from 'gsap'
import {useEffect, useMemo, useRef, useState} from 'react'

import {
  ArrowDownS,
  ArrowUpS,
  TestimonialsQuoteL,
  TestimonialsQuoteM,
  TestimonialsQuoteS,
} from '@/assets/specify'
import {componentTypes} from '@/common/enums'
import {TTestimonial, TTestimonialItem} from '@/common/types'
import DynamicComponent from '@/components/DynamicComponent'

import styles from './Testimonial.module.scss'
import {buildTimeline, handleClick, speed, TContext} from './TestimonialHelpers'

const Shape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox='0 0 1310 788'
      width='100%'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMinYMid slice'
      {...props}
    >
      <path d='M394 0 H1310 V788 H394' fill='currentColor' />
      <circle cx='394' cy='394' r='394' fill='currentColor' />
    </svg>
  )
}

const Testimonial = ({heading, testimonials = []}: TTestimonial): JSX.Element => {
  const [active, setActive] = useState(0)
  const timeline = useMemo(() => gsap.timeline({repeat: -1}), [])
  const testimonialsRef = useRef<HTMLDivElement>(null)
  const context: TContext = {length: testimonials.length, timeline, setActive}

  useEffect(() => {
    if (testimonialsRef.current) {
      buildTimeline(testimonialsRef.current.children, context)
    }

    return () => {
      timeline.kill()
    }
  }, [])

  return (
    <section className={styles.wrapper}>
      <div className={styles.background} />

      <div className={styles.section}>
        <Shape className={styles.shape} />

        <div className={styles.buttons}>
          <button className={styles.button} onClick={() => handleClick('prev', context)}>
            <ArrowUpS title='Previous' className={styles.arrow} />
          </button>

          <div className={styles.indexes}>
            <span className={styles.index}>{active + 1}</span>
            <span className={styles.divider}>{'•'}</span>
            <span className={styles.index}>{testimonials.length}</span>
          </div>

          <button className={styles.button} onClick={() => handleClick('next', context)}>
            <ArrowDownS title='Next' className={styles.arrow} />
          </button>
        </div>

        <div className={styles.content}>
          <TestimonialsQuoteS className={classNames(styles.watermark, styles.s)} />
          <TestimonialsQuoteM className={classNames(styles.watermark, styles.m)} />
          <TestimonialsQuoteL className={classNames(styles.watermark, styles.l)} />

          {heading && <h2 className={styles.heading}>{heading}</h2>}

          <div className={styles.testimonials} ref={testimonialsRef}>
            {testimonials.map((props, index) => {
              if (
                props.component !== componentTypes.TESTIMONIAL_ITEM &&
                props.component !== componentTypes.TESTIMONIAL_REFERENCE
              ) {
                return null
              }

              const extraProps: Partial<TTestimonialItem> = {
                animationDuration: speed,
                isActive: index === active,
              }

              return (
                <blockquote key={props._uid} className={styles.testimonial}>
                  <DynamicComponent {...extraProps} {...props} />
                </blockquote>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
