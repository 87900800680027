import {TCard} from '@/common/types'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './Card.module.scss'

const Card = ({logo, heading, countries, isLarge}: TCard): JSX.Element => {
  if (isLarge) {
    return (
      <article className={styles.card}>
        <div className={styles.logoLarge}>
          {logo && (
            <StoryblokImage
              className={styles.logo}
              src={logo.filename}
              alt=''
              sizes={{sm: 25, lg: 15}}
            />
          )}
        </div>
        {heading && <p className={styles.heading}>{heading}</p>}

        <div className={styles.countries}>
          {countries &&
            countries.map((country, index) => (
              <span key={index} className={`fi fi-${country} ${styles.country}`} />
            ))}
        </div>
      </article>
    )
  }

  return (
    <article className={styles.cardSmall}>
      <div className={styles.logoSmall}>
        {logo && (
          <StoryblokImage
            className={styles.logo}
            src={logo.filename}
            alt=''
            sizes={{sm: 20, lg: 10}}
          />
        )}
      </div>
      {heading && <p className={styles.headingSmall}>{heading}</p>}
    </article>
  )
}

export default Card
