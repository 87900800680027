import classNames from 'classnames'
import React from 'react'
import {render} from 'storyblok-rich-text-react-renderer'

import {componentTypes} from '@/common/enums'
import {TWithImageOverlap} from '@/common/types'
import ButtonStoryblok from '@/components/ButtonStoryblok'
import ScrollingTitle from '@/components/ScrollingTitle'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './WithImageOverlap.module.scss'

const WithImageOverlap = ({
  scrollingTitle,
  heading,
  subheading,
  firstParagraph,
  secondParagraph,
  image,
  buttons = [],
  isReversed,
  className,
}: TWithImageOverlap): JSX.Element => {
  const hasScrollingTitle = !!scrollingTitle

  const sharedClassName = classNames(className, {
    [styles.isReversed]: isReversed,
  })

  return (
    <section className={classNames(styles.section, {[styles.noPaddingTop]: hasScrollingTitle})}>
      {hasScrollingTitle && (
        <div className={styles.scrollingTitle}>
          <ScrollingTitle
            component={componentTypes.SCROLLING_TITLE}
            _uid=''
            heading={scrollingTitle}
            noPadding
          />
        </div>
      )}

      {image && (
        <div
          className={classNames(
            styles.picture,
            {[styles.alignTop]: hasScrollingTitle},
            sharedClassName
          )}
        >
          <StoryblokImage
            className={styles.image}
            src={image.filename}
            alt={image.alt}
            sizes={{lg: 50}}
          />
        </div>
      )}

      <article className={styles.container}>
        {heading && <h2 className={classNames(styles.heading, sharedClassName)}>{heading}</h2>}
        {subheading && (
          <h3 className={classNames(styles.subheading, sharedClassName)}>{subheading}</h3>
        )}

        <div className={classNames(styles.paragraphs, sharedClassName)}>
          {firstParagraph && (
            <div className={classNames(styles.paragraph, sharedClassName)}>
              {render(firstParagraph)}
            </div>
          )}
          {secondParagraph && (
            <div className={classNames(styles.paragraph, sharedClassName)}>
              {render(secondParagraph)}
            </div>
          )}
        </div>

        <div className={classNames(styles.buttons, sharedClassName)}>
          {buttons.map((props) => {
            if (props.component !== componentTypes.BUTTON_STORYBLOK) return null
            const isDefault = props.theme === 'default'
            return (
              <div
                key={props._uid}
                className={classNames(
                  styles.button,
                  {[styles.defaultButton]: isDefault},
                  sharedClassName
                )}
              >
                <ButtonStoryblok {...props} />
              </div>
            )
          })}
        </div>
      </article>
    </section>
  )
}

export default WithImageOverlap
