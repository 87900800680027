import classNames from 'classnames'
import gsap, {Power4} from 'gsap'

import {MoinsXs} from '@/assets/specify'
import {PlusXs} from '@/assets/specify'
import {TAccordionItem} from '@/common/types'
import ButtonTertiary from '@/components/ButtonTertiary'

import styles from './AccordionItem.module.scss'

const AccordionItem = ({
  heading,
  paragraph,
  btnLabel,
  btnLink,
  isAlternative,
  isActive,
  handleClick = () => undefined,
}: TAccordionItem): JSX.Element => {
  const sharedClassName = classNames({
    [styles.isAlternative]: isAlternative,
    [styles.isActive]: isActive,
  })

  const onClick = ({currentTarget}: React.SyntheticEvent) => {
    gsap.fromTo(currentTarget, {height: '0px'}, {height: 'auto', easing: Power4.easeOut})
  }

  return (
    <div
      className={classNames(styles.article, sharedClassName)}
      onClick={onClick}
      onKeyPress={onClick}
      role='button'
      tabIndex={0}
    >
      <div
        className={classNames(styles.item, sharedClassName)}
        onClick={handleClick}
        onKeyPress={handleClick}
        role='button'
        tabIndex={0}
      >
        <span className={classNames(styles.icon, sharedClassName)}>
          {isActive ? (
            <MoinsXs className={styles.minusIcon} />
          ) : (
            <PlusXs className={styles.plusIcon} />
          )}
        </span>

        {heading && (
          <div>
            <h2 className={classNames(styles.heading, sharedClassName)}>{heading}</h2>
          </div>
        )}
      </div>

      <div className={classNames(styles.content, sharedClassName)}>
        {paragraph && <p className={classNames(styles.paragraph, sharedClassName)}>{paragraph}</p>}

        <ButtonTertiary
          label={btnLabel}
          link={btnLink}
          className={classNames(styles.button, sharedClassName)}
          isDark={isAlternative && isAlternative}
        />
      </div>
    </div>
  )
}

export default AccordionItem
