import classNames from 'classnames'

import {TLogosV2} from '@/common/types'
import {TImage} from '@/common/types'
import Link from '@/components/Link'
import MarqueeV2 from '@/components/MarqueeV2/MarqueeV2'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './LogosV2.module.scss'

const LogosV2 = ({heading, isBoxed = false, images, labelLink, link}: TLogosV2): JSX.Element => {
  return (
    <section className={classNames(styles.section, {[styles.isBoxed]: isBoxed})}>
      {heading && (
        <div className={styles.grid}>
          <h2 className={styles.heading}>{heading}</h2>
        </div>
      )}
      <div className={styles.wrapper}>
        <MarqueeV2>
          <div className={classNames(styles.innermarquee, {[styles.isBoxed]: isBoxed})}>
            {images.map(({_uid, image, component}: TImage) => {
              if (!image || typeof image === 'string' || component !== 'image') return null
              return (
                <div key={_uid} className={classNames(styles.item, {[styles.isBoxed]: isBoxed})}>
                  <StoryblokImage src={image.filename} alt={image.alt} />
                </div>
              )
            })}
          </div>
        </MarqueeV2>
      </div>

      {labelLink && link && (
        <div className={styles.grid}>
          <div className={classNames(styles.linkwrapper, {[styles.isBoxed]: isBoxed})}>
            <Link link={link} className={styles.link}>
              {labelLink}
            </Link>
          </div>
        </div>
      )}
    </section>
  )
}

export default LogosV2
