import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgTestimonialsQuoteS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={360}
      height={246}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        opacity={0.1}
        d='M0 163.753c0 45.22 36.8 81.876 82.197 81.876 45.397 0 82.196-36.656 82.196-81.876 0-44.664-35.919-80.952-80.545-81.834 20.752-20.272 49.174-32.795 80.545-32.795V0C73.603 0 0 73.316 0 163.753zM360 0c-90.79 0-164.393 73.316-164.393 163.753 0 45.22 36.803 81.876 82.196 81.876 45.397 0 82.197-36.656 82.197-81.876 0-44.664-35.919-80.952-80.545-81.834C300.207 61.647 328.633 49.124 360 49.124V0z'
        fill='#fff'
      />
    </svg>
  )
}

export default SvgTestimonialsQuoteS
