export enum componentTypes {
  ACCORDION = 'accordion',
  ACCORDION_ITEM = 'accordion-item',
  BUTTON = 'button',
  BUTTON_SECONDARY = 'button-secondary',
  BUTTON_STORYBLOK = 'button',
  BUTTON_TERTIARY = 'button-tertiary',
  CARD = 'card',
  CARD_SLIDER = 'card-slider',
  CARD_SLIDER_ITEM = 'card-slider-item',
  CHANNEL_SELECTED = 'channel-selected',
  CHANNELS = 'channels',
  CHANNELS_V2 = 'channels-v2',
  CTA_CENTERED = 'cta-centered',
  CTA_DOUBLE = 'cta-double',
  CTA_SIMPLE = 'cta-simple',
  CTA_WITH_IMAGE = 'cta-with-image',
  DRAGGABLE_CARDS_LIST = 'draggable-cards-list',
  FEATURES = 'features',
  FEATURES_ITEM = 'features-item',
  FEATURESAI = 'features-ai',
  FEATURES_AI_V2 = 'features-ai-v2',
  FEATURESAI_ITEM = 'features-ai-item',
  FOOTER = 'footer',
  FOOTER_ADDRESS = 'footer-address',
  FOOTER_ITEM = 'footer-item',
  GLOBAL_REFERENCE = 'global-reference',
  GRID_CARD = 'grid-card',
  GRID_CARDS = 'grid-cards',
  HERO_COMPLEX = 'hero-complex',
  HERO_HOME = 'hero-home',
  HERO_HOME_V2 = 'hero-home-v2',
  HERO_SIMPLE = 'hero-simple',
  HERO_SIMPLE_V2 = 'hero-simple-v2',
  HERO_WITH_PARTNER_LOGO = 'hero-with-partner-logo',
  IMAGE = 'image',
  INDUSTRY_CARD = 'industry-card',
  INDUSTRY_CARDS = 'industry-cards',
  INDUSTRY_CARDS_CATEGORY = 'industry-cards-category',
  LINK = 'link',
  LOGO_LIST = 'logo-list',
  LOGO_LIST_ITEM = 'logo-list-item',
  LOGOS = 'logos',
  LOGOS_V2 = 'logos_v2',
  MASONRY_CARD = 'masonry-card',
  MASONRY_CARDS = 'masonry-cards',
  NAVIGATION = 'navigation',
  NAVIGATION_ITEM = 'navigation-item',
  NAVIGATION_ITEM_RICH = 'navigation-item-rich',
  NAVIGATION_ITEM_RICH_LIST = 'navigation-item-rich-list',
  NAVIGATION_ITEM_RICH_LIST_ITEM = 'navigation-item-rich-list-item',
  NAVIGATION_ITEM_RICH_CARDS = 'navigation-item-rich-cards',
  NAVIGATION_ITEM_RICH_CARDS_ITEM = 'navigation-item-rich-cards-item',
  NAVIGATION_V2 = 'navigation-v2',
  RESOURCE_ITEM = 'resource-item',
  REVIEW = 'review',
  SCROLLING_TITLE = 'scrolling-title',
  SPLIT_LIST_WITH_IMAGE_V2 = 'split-list-with-image-v2',
  SPLIT_WITH_IMAGE = 'split-with-image',
  SPLIT_WITH_IMAGE_V2 = 'split-with-image-v2',
  TESTIMONIAL = 'testimonial',
  TESTIMONIAL_ITEM = 'testimonial-item',
  TESTIMONIAL_REFERENCE = 'testimonial-reference',
  TITLE = 'title',
  TITLE_V2 = 'title_v2',
  TITLE_WITH_PARAGRAPH = 'title-with-paragraph',
  VERTICAL_SLIDER = 'vertical-slider',
  VERTICAL_SLIDER_ITEM = 'vertical-slider-item',
  VIDEO = 'video',
  WITH_FULL_WIDTH_IMAGE = 'with-full-width-image',
  WITH_IMAGE_OVERLAP = 'with-image-overlap',
  WIZARD = 'wizard',
  WIZARD_OPTION = 'wizard-option',
}

export enum breakpoints {
  LG = 1280,
  MD = 748,
}

export enum translationNamespaces {
  CHANNELS_V2_COUNTRIES = 'channels-countries',
  CHANNELS_V2_CATEGORIES = 'channels-categories',
  CHANNELS_V2_INDUSTRIES = 'channels-industries',
  CHANNELS_V2_FEATURES = 'channels-features',
}
