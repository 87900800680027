import classNames from 'classnames'

import {TBoxShadow} from '@/common/types'

import styles from './BoxShadow.module.scss'

const BoxShadow = ({className}: TBoxShadow): JSX.Element => (
  <div className={classNames(className, styles.shadow)} />
)

export default BoxShadow
