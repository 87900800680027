import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgMenuS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M4.5 6A1.5 1.5 0 016 4.5h12a1.5 1.5 0 010 3H6A1.5 1.5 0 014.5 6zM3 12a1.5 1.5 0 011.5-1.5h15a1.5 1.5 0 010 3h-15A1.5 1.5 0 013 12zm3 4.5a1.5 1.5 0 000 3h12a1.5 1.5 0 000-3H6z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgMenuS
