import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgMoinsXs({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={12}
      height={12}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M2.602 6.605l2.796.01 1.193-.001 2.796-.017A.58.58 0 009.984 6a.576.576 0 00-.595-.595l-2.784.003L5.4 5.396l-2.796.017a.58.58 0 00-.597.597c0 .159.066.304.185.424a.58.58 0 00.41.171z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgMoinsXs
