import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useMemo, useRef} from 'react'

import {componentTypes} from '@/common/enums'
import {TVerticalSliderPrivate} from '@/common/types'
import DynamicComponent from '@/components/DynamicComponent'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './VerticalSliderMobile.module.scss'

gsap.registerPlugin(ScrollTrigger)

const VerticalSlider = ({isLight, slides = []}: TVerticalSliderPrivate): JSX.Element => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const dotsRef = useRef<HTMLDivElement>(null)
  const timeline = useMemo(() => gsap.timeline(), [])

  const images = slides.map((slide) => {
    if (slide.component === componentTypes.VERTICAL_SLIDER_ITEM && slide.image) {
      return slide.image
    }
  })

  useEffect(() => {
    if (sectionRef.current) {
      const items = Array.from(sectionRef.current.children).reduce(
        (acc: Element[], child) => [...acc, ...Array.from(child.children)],
        []
      )

      items.forEach((item) => {
        timeline.fromTo(
          item,
          {opacity: 0},
          {
            opacity: 1,
            scrollTrigger: {
              start: 'top bottom',
              end: 'top top',
              scrub: 0.5,
              trigger: item,
            },
          }
        )
      })

      if (dotsRef.current) {
        ScrollTrigger.create({
          trigger: sectionRef.current,
          start: 'top top',
          end: 'bottom bottom',
          pin: dotsRef.current,
          pinSpacing: false,
        })
      }
    }
  }, [])

  return (
    <section className={classNames(styles.section, {[styles.isLight]: isLight})} ref={sectionRef}>
      {slides.map((props, index) => {
        if (props.component !== componentTypes.VERTICAL_SLIDER_ITEM) return null
        const image = images[index]
        return (
          <div key={props._uid} className={styles.item} data-index={index}>
            {image && (
              <div className={styles.picture}>
                <StoryblokImage className={styles.image} src={image.filename} alt={image.alt} />
              </div>
            )}
            <DynamicComponent {...props} isLight={isLight} index={index + 1} />
          </div>
        )
      })}
    </section>
  )
}

export default VerticalSlider
