import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import {useEffect, useMemo, useRef} from 'react'

import {TImage} from '@/common/types'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './Image.module.scss'

gsap.registerPlugin(ScrollTrigger)

const Image = ({image, className, withLoader, wrapperClassName, sizes}: TImage): JSX.Element => {
  if (!image || typeof image === 'string') return <div />

  const containerRef = useRef<HTMLDivElement>(null)
  const panelRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const timeline = useMemo(() => gsap.timeline(), [])

  useEffect(() => {
    if (withLoader) {
      timeline
        .to(panelRef.current, {
          y: '0%',
        })
        .set(imageRef.current, {opacity: 1})
        .to(panelRef.current, {
          y: '-101%',
        })
    }
  }, [])

  return (
    <div
      className={classNames(wrapperClassName, {[styles.withLoader]: withLoader})}
      ref={containerRef}
    >
      {withLoader && <div className={styles.loader} ref={panelRef} />}
      <StoryblokImage
        src={image.filename}
        alt={''}
        ref={imageRef}
        className={classNames(styles.image, className)}
        sizes={sizes}
      />
    </div>
  )
}

export default Image
