import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgTraductionS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.919 5.7A6.486 6.486 0 001.5 8c0 .81.148 1.585.419 2.3h2.023c-.13-.727-.199-1.5-.199-2.3 0-.8.07-1.573.2-2.3H1.918zm.48-1h1.768c.19-.695.438-1.331.736-1.886.146-.272.306-.529.48-.766A6.526 6.526 0 002.4 4.7zm5.6-4.2a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm-.5 1.082c-.605.2-1.21.763-1.715 1.705A7.79 7.79 0 005.207 4.7H7.5V1.582zm0 4.118H4.96A12.03 12.03 0 004.744 8c0 .813.077 1.587.217 2.3H7.5V5.7zm1 4.6V5.7h2.541c.14.713.217 1.487.217 2.3 0 .813-.078 1.587-.217 2.3H8.5zm-1 1H5.208c.16.523.354.998.577 1.413.505.942 1.11 1.505 1.716 1.705V11.3zm-2.115 2.652a6.408 6.408 0 01-.481-.766 9.026 9.026 0 01-.736-1.886H2.399a6.526 6.526 0 002.985 2.652zm3.116.466V11.3h2.292a7.786 7.786 0 01-.576 1.413c-.506.942-1.11 1.505-1.716 1.705zm3.333-3.118a9.028 9.028 0 01-.736 1.886 6.391 6.391 0 01-.481.766A6.526 6.526 0 0013.6 11.3h-1.768zm2.248-1c.27-.715.419-1.49.419-2.3 0-.81-.148-1.585-.419-2.3h-2.024c.13.727.2 1.5.2 2.3 0 .8-.07 1.573-.2 2.3h2.024zm-2.248-5.6a9.027 9.027 0 00-.736-1.886 6.4 6.4 0 00-.481-.766A6.527 6.527 0 0113.6 4.7h-1.768zm-1.04 0H8.5V1.582c.606.2 1.21.763 1.716 1.705.223.415.417.89.576 1.413z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgTraductionS
