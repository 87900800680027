import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgShoppingfeedLogoReview({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={104}
      height={104}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M51.999 103.998c28.718 0 51.999-23.28 51.999-51.999C103.998 23.281 80.718 0 51.999 0 23.281 0 0 23.28 0 51.999c0 28.718 23.28 51.999 51.999 51.999z'
        fill='#221F61'
      />
      <path
        d='M35.049 60.194l-1.843-2.62 7.82-5.446c-5.813-3.726-9.498-10.237-9.498-17.197 0-11.22 9.13-20.35 20.35-20.35 10.604 0 19.489 8.271 20.308 18.835l.082.9-37.22 25.878zm16.828-42.378c-9.458 0-17.156 7.698-17.156 17.156 0 6.305 3.603 12.201 9.172 15.19l25.017-17.36c-1.105-8.476-8.393-14.986-17.033-14.986z'
        fill='#77CEF2'
      />
      <path
        d='M51.918 89.306c-10.605 0-19.49-8.271-20.308-18.835l-.082-.9 37.218-25.878 1.843 2.62-7.82 5.446c5.814 3.726 9.499 10.236 9.499 17.197 0 11.219-9.131 20.35-20.35 20.35zM34.926 71.167c1.105 8.476 8.394 14.945 17.033 14.945 9.458 0 17.156-7.698 17.156-17.156 0-6.306-3.603-12.202-9.172-15.19l-25.017 17.4z'
        fill='#9479F7'
      />
    </svg>
  )
}

export default SvgShoppingfeedLogoReview
