import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgArrowLeftS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M4.966 12.166a.8.8 0 001.131-1.132L3.863 8.8H14.73a.8.8 0 000-1.6H3.863l2.234-2.234a.8.8 0 10-1.131-1.132l-3.6 3.6L.8 8l.565.565 3.6 3.6z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgArrowLeftS
