import React from 'react'

import {TVerticalSlider} from '@/common/types'
import VerticalSliderDesktop from '@/components/VerticalSlider/VerticalSliderDesktop'
import VerticalSliderMobile from '@/components/VerticalSlider/VerticalSliderMobile'

const VerticalSlider = ({isLight, slides = []}: TVerticalSlider): JSX.Element => {
  return (
    <>
      <VerticalSliderDesktop slides={slides} isLight={isLight} />
      <VerticalSliderMobile slides={slides} isLight={isLight} />
    </>
  )
}

export default VerticalSlider
