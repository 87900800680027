import {componentTypes} from '@/common/enums'
import {TCtaWithImage} from '@/common/types'
import ButtonStoryblok from '@/components/ButtonStoryblok'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './CtaWithImage.module.scss'
import Shape from './Shape'

const CtaWithImage = ({heading, buttons = [], image}: TCtaWithImage): JSX.Element => {
  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <div className={styles.fill} />
        <Shape className={styles.shape} />
      </div>

      {heading && (
        <article className={styles.content}>
          <h2 className={styles.heading}>{heading}</h2>
          {buttons.map((props, index) => {
            if (index > 0) return null
            if (props.component !== componentTypes.BUTTON_STORYBLOK) return null
            return (
              <div key={props._uid} className={styles.button}>
                <ButtonStoryblok {...props} theme='default' isSecondary />
              </div>
            )
          })}
        </article>
      )}

      {image && (
        <div className={styles.picture}>
          <StoryblokImage className={styles.image} src={image.filename} alt={image.alt} />
        </div>
      )}
    </section>
  )
}

export default CtaWithImage
