import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function Shape({title, titleId, ...props}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width='1392'
      height='788'
      viewBox='0 0 1392 788'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M0 394C0 176.4 176.4 0 394 0H1392V788H394C176.4 788 0 611.6 0 394Z'
        fill='url(#paint0_linear_2635_50909)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2635_50909'
          x1='1392'
          y1='788'
          x2='148.082'
          y2='-188.613'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#77CEF2' />
          <stop offset='1' stopColor='#9479F7' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Shape
