import * as React from 'react'

function BackgroundCircle({className, ...props}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={className}
      width='600'
      height='696'
      viewBox='0 0 600 696'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0 348C0 155.805 155.805 0 348 0H600V696H348C155.805 696 0 540.195 0 348Z' />
    </svg>
  )
}

export default BackgroundCircle
