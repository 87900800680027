import classNames from 'classnames'

import {
  ShoppingfeedLogoReview as Logo,
  TestimonialsQuoteL,
  TestimonialsQuoteM,
  TestimonialsQuoteS,
} from '@/assets/specify'
import {TReview} from '@/common/types'

import styles from './Review.module.scss'
import Shape from './Shape'

const Review = ({heading, paragraph}: TReview): JSX.Element => {
  if (!heading || !paragraph) return <div />

  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <Shape className={styles.shape} preserveAspectRatio='xMinYMid slice' />
        <TestimonialsQuoteS className={classNames(styles.watermark, styles.s)} />
        <TestimonialsQuoteM className={classNames(styles.watermark, styles.m)} />
        <TestimonialsQuoteL className={classNames(styles.watermark, styles.l)} />
        <Logo className={styles.logo} />
      </div>
      <div className={styles.content}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
      </div>
    </section>
  )
}

export default Review
