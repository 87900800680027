import classNames from 'classnames'
import {render} from 'storyblok-rich-text-react-renderer'

import {TSplitWithImage} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import Button from '@/components/Button'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './SplitWithImage.module.scss'

const Shape = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox='0 0 704 792' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M704 0H396C177.295 0 0 177.295 0 396C0 614.705 177.295 792 396 792H704V0Z'
        fill='currentColor'
      />
    </svg>
  )
}

const SplitWithImage = ({
  heading: subheading, // Legacy - Wrong naming convention
  subHeading: heading, // Legacy - Wrong naming convention
  paragraph,
  btnLabel,
  btnLink,
  image,
  video,
  isFlipped,
  theme,
}: TSplitWithImage): JSX.Element => {
  const themeClasseName = theme ? styles[theme] : ''

  const hasSubheading =
    subheading &&
    subheading.content &&
    subheading.content[0] &&
    (subheading.content[0] as {content: unknown[]}).content

  const HeadingTag = !hasSubheading ? 'h2' : 'h3'

  return (
    <section
      className={classNames(styles.section, themeClasseName, {[styles.isFlipped]: isFlipped})}
    >
      <div className={styles.container}>
        {(!video || !video.filename) && image && image.filename && (
          <div className={styles.picture}>
            <Shape className={styles.shape} preserveAspectRatio='none' />
            <StoryblokImage
              className={styles.image}
              src={image.filename}
              alt={image.alt}
              loading='lazy'
              sizes={{lg: 50}}
            />
          </div>
        )}

        {video && video.filename && (
          <div className={classNames(styles.picture, styles.isVideo)}>
            <video
              className={styles.video}
              autoPlay
              loop
              muted
              poster={image && image.filename}
              preload='auto'
            >
              <source src={video.filename} />
            </video>
          </div>
        )}

        <div className={styles.content}>
          {hasSubheading && (
            <h2 className={classNames(themeClasseName, styles.subheading)}>
              {render(subheading, unwrapRenderParagraph)}
            </h2>
          )}

          {heading && (
            <HeadingTag className={styles.heading}>
              {render(heading, unwrapRenderParagraph)}
            </HeadingTag>
          )}

          {paragraph && (
            <p className={styles.paragraph}>{render(paragraph, unwrapRenderParagraph)}</p>
          )}

          {btnLink && btnLabel && (
            <div className={styles.button}>
              <Button link={btnLink} label={btnLabel} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default SplitWithImage
