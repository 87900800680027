import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgWizardLogo({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={72}
      height={72}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g clipPath='url(#wizard-logo_svg__a)'>
        <path
          d='M36 71.999c19.881 0 35.999-16.118 35.999-36S55.88 0 35.999 0 0 16.117 0 36c0 19.88 16.117 35.998 36 35.998z'
          fill='#fff'
        />
        <path
          d='M24.612 42.098l-1.249-1.837 5.364-3.747a14.123 14.123 0 01-6.54-11.902c0-7.788 6.32-14.032 14.033-14.032 7.347 0 13.445 5.73 14.033 13.004l.074.66-25.715 17.854zm11.682-29.314a11.82 11.82 0 00-11.829 11.828c0 4.335 2.498 8.45 6.319 10.506l17.265-11.975c-.808-5.878-5.804-10.36-11.755-10.36zm-.074 49.371c-7.346 0-13.444-5.73-14.032-13.004l-.074-.661L47.83 30.637l1.249 1.837-5.364 3.746a14.123 14.123 0 016.54 11.902c.073 7.715-6.246 14.033-14.033 14.033zm-11.755-12.49c.735 5.878 5.805 10.36 11.756 10.36a11.82 11.82 0 0011.828-11.83c0-4.334-2.498-8.448-6.318-10.505L24.465 49.665z'
          fill='#221F61'
        />
      </g>
      <defs>
        <clipPath id='wizard-logo_svg__a'>
          <path fill='#fff' d='M0 0h72v72H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgWizardLogo
