import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgHeroComplexArrowRound({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={280}
      height={280}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M217.868 110.995a93.529 93.529 0 00-110.83-42.751l7.729-17.91-20.08-8.578-24.401 55.27 55.271 24.401 9.074-19.947-24.785-10.904a71.694 71.694 0 0189.112 31.337 72.066 72.066 0 01-26.642 99.429 75.697 75.697 0 01-57.249 7.838l-5.595 20.88a97.898 97.898 0 0073.762-9.808 93.523 93.523 0 0034.634-129.257v0z'
        stroke='url(#hero-complex-arrow-round_svg__a)'
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap='round'
      />
      <defs>
        <linearGradient
          id='hero-complex-arrow-round_svg__a'
          x1={123.669}
          y1={274.649}
          x2={125.803}
          y2={5.475}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#77CEF2' />
          <stop offset={1} stopColor='#9479F7' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgHeroComplexArrowRound
