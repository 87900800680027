import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgTestimonialsQuoteM({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={469}
      height={320}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        opacity={0.1}
        d='M0 213.333C0 272.245 47.942 320 107.084 320s107.084-47.755 107.084-106.667c0-58.187-46.795-105.462-104.933-106.61 27.036-26.41 64.064-42.725 104.933-42.725V0C95.888 0 0 95.515 0 213.333zM469 0C350.721 0 254.832 95.515 254.832 213.333c0 58.912 47.947 106.667 107.084 106.667C421.058 320 469 272.245 469 213.333c0-58.187-46.794-105.462-104.933-106.61C391.103 80.313 428.136 63.998 469 63.998V0z'
        fill='#fff'
      />
    </svg>
  )
}

export default SvgTestimonialsQuoteM
