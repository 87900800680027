import React, {forwardRef} from 'react'

import {Sizes} from '@/common/types'

import {getSet, getSizes, getSrc} from './StoryblokImageHelpers'

type StoryblokImage = Omit<JSX.IntrinsicElements['img'], 'sizes'> & {
  sizes?: Sizes
  isFullWidth?: boolean
}

const StoryblokImage = (
  {src, sizes, isFullWidth, ...props}: StoryblokImage,
  ref?: React.LegacyRef<HTMLImageElement>
): JSX.Element | null => {
  if (!src) return null

  const {alt = '', loading = 'lazy'} = props
  const newSrc = getSrc(src)
  const srcSet = getSet(src)
  const newSizes = isFullWidth ? '100vw' : getSizes(src, sizes)

  return (
    <img
      {...props}
      alt={alt || ''}
      src={newSrc}
      srcSet={srcSet}
      sizes={newSizes}
      loading={loading}
      ref={ref}
    />
  )
}

export default forwardRef(StoryblokImage)
