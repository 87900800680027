import classNames from 'classnames'

import {TTitleV2} from '@/common/types'

import styles from './TitleV2.module.scss'

const TitleV2 = ({heading, paragraph, isCentered, theme}: TTitleV2): JSX.Element => {
  const sharedClassName = classNames({
    [styles.isAcquisition]: theme === 'acquisition',
    [styles.isMarketplace]: theme === 'marketplace',
  })

  const ParagraphTag = heading ? 'p' : 'h2'

  if (!heading && !paragraph) return <div />

  return (
    <section className={classNames(styles.section, {[styles.isCentered]: isCentered})}>
      {heading && <h2 className={classNames(styles.heading, sharedClassName)}>{heading}</h2>}
      {paragraph && (
        <ParagraphTag className={classNames(styles.paragraph, sharedClassName)}>
          {paragraph}
        </ParagraphTag>
      )}
    </section>
  )
}

export default TitleV2
