import classNames from 'classnames'
import {useRouter} from 'next/router'
import Select, {components, SingleValue} from 'react-select'

import {TraductionS} from '@/assets/specify'
import {TLanguageSwitcher, TLanguageSwitcherOption} from '@/common/types'

import styles from './LanguageSwitcher.module.scss'

function getLabel(label?: string): string {
  switch (label) {
    case 'fr':
      return 'Français'
    case 'en':
      return 'English'
    case 'es':
      return 'Español'
    case 'it':
      return 'Italiano'
    case 'de':
      return 'Deutsch'
    case 'ja':
      return '日本語'
    default:
      return ''
  }
}

const Null = () => null

const LanguageSwitcher = ({languages = [], isLight}: TLanguageSwitcher): JSX.Element => {
  const router = useRouter()
  const active = languages.findIndex(({isActive}) => isActive)

  function handleChange(option: SingleValue<TLanguageSwitcherOption>): void {
    if (option && option.value) {
      document.cookie = `lang_${option.label}=true;path=/; max-age=${60 * 60 * 24 * 60}`
      languages.forEach((lang) => {
        if (lang.label && option.label && lang.label !== option.label) {
          document.cookie = `lang_${lang.label}= ;path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`
        }
      })
      router.push(option.value.replace('index', ''))
    }
  }

  return (
    <div className={classNames(styles.container, {[styles.isLight]: isLight})}>
      <TraductionS className={styles.icon} />
      <Select
        // https://github.com/JedWatson/react-select/issues/2629
        id='language-switcher'
        instanceId='language-switcher'
        //
        options={languages}
        className={styles.select}
        classNamePrefix='react-select'
        components={{
          IndicatorSeparator: Null,
          Input: Null,
          Option: function Option(props) {
            return <components.Option {...props}>{getLabel(props.data.label)}</components.Option>
          },
        }}
        isMulti={false}
        value={languages[active]}
        onChange={handleChange}
        menuPlacement='auto'
      />
    </div>
  )
}

export default LanguageSwitcher
