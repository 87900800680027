import classNames from 'classnames'
import {gsap} from 'gsap'
import {Draggable} from 'gsap/dist/Draggable'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import {useEffect, useRef, useState} from 'react'
import * as React from 'react'
import Select from 'react-select'
import {MARK_BOLD, MARK_ITALIC, render} from 'storyblok-rich-text-react-renderer'

import {componentTypes} from '@/common/enums'
import {TFeaturesAi} from '@/common/types'
import {notUndefined} from '@/common/utils'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './FeaturesAi.module.scss'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(Draggable)
  gsap.registerPlugin(ScrollTrigger)
}

const FeaturesAi = ({
  heading,
  paragraph,
  // caption,
  items = [],
  labelWithout,
  labelWith,
}: TFeaturesAi): JSX.Element => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLDivElement>(null)
  const imagewrapperRef = useRef<HTMLDivElement>(null)
  const ctrlsRef = useRef<HTMLDivElement>(null)

  const defaultItem = items.findIndex(
    (i) => i.component === componentTypes.FEATURESAI_ITEM && i.isDefault
  )
  const defaultIndex = defaultItem > -1 ? defaultItem : 0
  const [activeItem, setActiveItem] = useState(defaultIndex)

  const options = items
    .map((item, index) => {
      if (item.component !== componentTypes.FEATURESAI_ITEM) return
      return {
        label: item.label,
        value: index,
      }
    })
    .filter(notUndefined)

  const onChange = (e: (typeof options)[number] | null) => {
    if (e) setActiveItem(e.value)

    setTimeout(() => {
      if (imageRef.current) {
        imageRef.current.scrollIntoView({behavior: 'smooth'})
      }
    }, 50)
  }

  useEffect(() => {
    const xMovement = imagewrapperRef.current ? (imagewrapperRef.current.clientWidth * 10) / 100 : 0
    const shakeLine = gsap.timeline()
    shakeLine.to(ctrlsRef.current, {x: xMovement, duration: 0.3})
    shakeLine.to(
      '.imagesAiContainers',
      {
        clipPath: `polygon(${60}% 0, 100% 0, 100% 100%, ${60}% 100%)`,
        duration: 0.3,
      },
      '<'
    )
    shakeLine.to(ctrlsRef.current, {x: -xMovement, duration: 0.3})
    shakeLine.to(
      '.imagesAiContainers',
      {
        clipPath: `polygon(${40}% 0, 100% 0, 100% 100%, ${40}% 100%)`,
        duration: 0.3,
      },
      '<'
    )
    shakeLine.to(ctrlsRef.current, {x: 0, duration: 0.3})
    shakeLine.to(
      '.imagesAiContainers',
      {
        clipPath: `polygon(${50}% 0, 100% 0, 100% 100%, ${50}% 100%)`,
        duration: 0.3,
      },
      '<'
    )

    ScrollTrigger.create({
      animation: shakeLine,
      trigger: sectionRef.current,
    })

    Draggable.create(ctrlsRef.current, {
      type: 'x',
      bounds: imagewrapperRef.current,
      onDrag: function () {
        const posX = Math.abs(Math.round(this.minX + this.x))
        const xpercent = 100 - (posX * 100) / (this.maxX - this.minX)
        // gsap.set('.imagesAiContainers', {left: `calc(50% + ${this.x - 2}px)`})
        // gsap.set('.imagesAiContainers', {clipPath: `${xpercent}%)`})
        gsap.set('.imagesAiContainers', {
          clipPath: `polygon(${xpercent}% 0, 100% 0, 100% 100%, ${xpercent}% 100%)`,
        })
      },
    })
  }, [])

  return (
    <section className={styles.section} ref={sectionRef}>
      <div className={styles.content}>
        <h2 className={styles.heading}>{heading}</h2>
        <div className={styles.paragraph}>
          {render(paragraph, {
            markResolvers: {
              // eslint-disable-next-line react/display-name
              [MARK_BOLD]: (children: string) => <b className={styles.bold}>{children}</b>,
              // eslint-disable-next-line react/display-name
              [MARK_ITALIC]: () => (
                <Select
                  isSearchable={false}
                  className={styles.select}
                  classNamePrefix='react-select'
                  components={{
                    DropdownIndicator: () => (
                      <svg width='32' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <rect width='32' height='32' rx='16' fill='url(#a)' />
                        <path
                          d='m15.031 20.848-6.75-6.75c-.351-.317-.351-.844 0-1.196.316-.316.844-.316 1.195 0l6.153 6.188 6.152-6.152c.317-.352.844-.352 1.195 0a.828.828 0 0 1 0 1.16l-6.785 6.75a.77.77 0 0 1-1.16 0Z'
                          fill='#F7F6FB'
                        />
                        <defs>
                          <linearGradient
                            id='a'
                            x1='32'
                            y1='32'
                            x2='-6.598'
                            y2='14.845'
                            gradientUnits='userSpaceOnUse'
                          >
                            <stop stopColor='#9479F7' />
                            <stop offset='1' stopColor='#8ADCFE' />
                          </linearGradient>
                        </defs>
                      </svg>
                    ),
                    IndicatorSeparator: () => null,
                  }}
                  options={options}
                  onChange={onChange}
                  defaultValue={options[defaultIndex]}
                />
              ),
            },
          })}
        </div>
        {/* <p className={styles.caption}>{caption}</p> */}

        <div className={styles.arrow}>
          <svg
            width='388'
            height='49'
            viewBox='0 0 388 49'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M0 1H145V47L196.5 1H387.5' stroke='black' />
          </svg>
        </div>

        <div className={styles.avatar}>
          <img src={'/avatar.png'} alt='a.i.lice avatar' />
        </div>
      </div>

      <div className={styles.images} ref={imageRef}>
        <div className={styles.imageswrapper} ref={imagewrapperRef}>
          {items.map((item, index) => {
            if (item.component !== componentTypes.FEATURESAI_ITEM) return null
            if (!item.image || !item.image.filename || !item.imageAi || !item.imageAi.filename)
              return null
            return (
              <div
                key={item._uid}
                className={classNames(styles.image, {[styles.isActive]: index === activeItem})}
              >
                <div className={styles.imageContainer}>
                  <StoryblokImage
                    alt={item.image.alt}
                    src={item.image.filename}
                    sizes={{lg: 50}}
                    style={{visibility: index === activeItem ? 'visible' : 'hidden'}}
                  />
                  <div className={classNames(styles.labelWithout, styles.label)}>
                    <p>{labelWithout}</p>
                  </div>
                </div>
                <div className={styles.imageContainer}>
                  <div className={classNames('imagesAiContainers', styles.imageAiContainer)}>
                    <StoryblokImage
                      alt={item.imageAi.alt}
                      src={item.imageAi.filename}
                      sizes={{lg: 50}}
                      style={{visibility: index === activeItem ? 'visible' : 'hidden'}}
                    />

                    <div className={classNames(styles.labelWith, styles.label)}>
                      <p>{labelWith}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

          <div className={styles.compCtrlWrapper} ref={ctrlsRef}>
            <div className={styles.compCtrl}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                <path
                  fill='#8bb2f0'
                  d='M503.5 239L332.8 111c-6.4-5-15-5.7-22.3-2-7.3 3.5-11.8 11-11.8 19v64h-85.4v-64c0-8-4.5-15.5-11.8-19-7.2-3.7-15.8-3-22.3 2L8.5 239C3.2 243 0 249.2 0 256c0 6.7 3.2 13 8.5 17l170.7 128c6.4 5 15 5.7 22.3 2 7.3-3.5 11.8-11 11.8-19v-64h85.4v64c0 8 4.5 15.5 11.8 19 3 1.6 6.3 2.3 9.5 2.3 4.5 0 9-1.4 12.8-4.2l170.7-128c5.3-4 8.5-10.3 8.5-17 0-6.7-3.2-13-8.5-17z'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesAi
