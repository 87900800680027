import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import {TChannelsV2, TChannelsV2Cards, TChannelsV2Channel} from '@/common/types'

import ButtonSecondary from '../ButtonSecondary'
import Cards from './Cards'
import styles from './ChannelsV2.module.scss'
import Empty from './Empty/'
import Filters from './Filters'

const emptyState: {highlighted: TChannelsV2Channel[]; regular: TChannelsV2Channel[]} = {
  highlighted: [],
  regular: [],
}

const ChannelsV2 = ({
  locale,
  channels,
  contentHighlightedHeading,
  contentRegularHeading,
  contentSearchHeading,
  filtersMobileOpenButton,
  filtersMobileSearchButton,
  filtersDesktopChannelsCount,
  filtersSearchHeading,
  filtersSearchPlaceholder,
  filtersCategoriesHeading,
  filtersIndustriesHeading,
  filtersCountriesHeading,
  cardsShowMoreLabel,
  cardsWebsiteLabel,
  cardsFindOutMoreLabel,
  noResultsHeading,
  noResultsDescription,
  noResultsButtons,
}: TChannelsV2): JSX.Element => {
  const channelsList = useMemo(
    () => (channels && locale ? channels[locale] : undefined),
    [channels, locale]
  )

  const filtersOpenButtonRef = useRef<HTMLDivElement>(null)
  const [results, setResults] = useState<TChannelsV2Channel[]>()
  const [filteredChannels, setFilteredChannels] = useState(emptyState)
  const [showFilters, setShowFilters] = useState(false)

  const handleCloseFilters = useCallback(function () {
    setShowFilters(false)
    if (filtersOpenButtonRef.current) {
      filtersOpenButtonRef.current.scrollIntoView()
    }
  }, [])

  const cardsSharedProps: TChannelsV2Cards = {
    showMoreLabel: cardsShowMoreLabel,
    websiteLabel: cardsWebsiteLabel,
    findOutMoreLabel: cardsFindOutMoreLabel,
  }

  useEffect(() => {
    const items = results || channelsList || []
    const filteredChannels = items
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1
        } else if (b.name > a.name) {
          return 1
        } else {
          return 0
        }
      })
      .sort((a, b) => {
        if (a.content.isFamous && !b.content.isFamous) {
          return -1
        } else if (b.content.isFamous && !a.content.isFamous) {
          return 1
        } else {
          return 0
        }
      })
      .sort((a, b) => {
        if (a.content.isHighlighted && !b.content.isHighlighted) {
          return -1
        } else if (b.content.isHighlighted && !a.content.isHighlighted) {
          return 1
        } else {
          return 0
        }
      })
      .reduce(
        (all: {highlighted: TChannelsV2Channel[]; regular: TChannelsV2Channel[]}, channel) => {
          if (channel.content.isHighlighted) {
            return {...all, highlighted: all.highlighted.concat(channel)}
          } else {
            return {...all, regular: all.regular.concat(channel)}
          }
        },
        {highlighted: [], regular: []}
      )

    setFilteredChannels(filteredChannels)
  }, [channels, results])

  if (!locale || !channels || !channels[locale]) {
    return <div />
  }

  return (
    <section className={styles.section}>
      <div className={styles.filters}>
        <div className={styles.filtersButton} ref={filtersOpenButtonRef}>
          <ButtonSecondary label={filtersMobileOpenButton} onClick={() => setShowFilters(true)} />
        </div>
        <Filters
          channels={channelsList}
          onChange={setResults}
          mobileSearchButton={filtersMobileSearchButton}
          desktopChannelsCount={filtersDesktopChannelsCount}
          searchHeading={filtersSearchHeading}
          searchPlaceholder={filtersSearchPlaceholder}
          categoriesHeading={filtersCategoriesHeading}
          industriesHeading={filtersIndustriesHeading}
          countriesHeading={filtersCountriesHeading}
          isVisible={showFilters}
          onClose={handleCloseFilters}
        />
      </div>
      <div className={styles.content}>
        {!results && (
          <>
            <div className={styles.cards}>
              <Cards
                {...cardsSharedProps}
                channels={filteredChannels.highlighted}
                isLarge
                heading={contentHighlightedHeading}
              />
            </div>
            <hr className={styles.hr} />
            <div className={styles.cards}>
              <Cards
                {...cardsSharedProps}
                channels={filteredChannels.regular}
                heading={contentRegularHeading}
              />
            </div>
          </>
        )}
        {results && results.length > 0 && (
          <div className={styles.cards}>
            <Cards
              {...cardsSharedProps}
              channels={filteredChannels.highlighted.concat(filteredChannels.regular)}
              heading={contentSearchHeading}
            />
          </div>
        )}
        {results && results.length === 0 && (
          <div className={styles.cards}>
            <Empty
              heading={noResultsHeading}
              description={noResultsDescription}
              buttons={noResultsButtons}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default ChannelsV2
