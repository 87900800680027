import {Disclosure} from '@headlessui/react'
import classNames from 'classnames'
import {useRouter} from 'next/router'
import {useEffect, useState} from 'react'

import {CloseS, LogoNavy, LogoStandaloneNavy, MenuS} from '@/assets/specify'
import SvgChevronDownXs from '@/assets/specify/ChevronDownXs'
import {componentTypes} from '@/common/enums'
import {TNavigation, TNavigationItem} from '@/common/types'
import Button from '@/components/Button'
import NavigationItem from '@/components/NavigationItem'
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage'

import LanguageSwitcher from '../LanguageSwitcher'
import Link from '../Link'
import styles from './NavigationMobile.module.scss'

type TCallToAction = {
  className?: string
} & Pick<TNavigation, 'btnLink' | 'btnLabel'>

const CallToAction = ({className, btnLink, btnLabel}: TCallToAction): JSX.Element | null => {
  if (!btnLabel && !btnLink) return null
  return (
    <div className={className}>
      <Button link={btnLink} label={btnLabel} />
    </div>
  )
}

const MobileNavigation = ({
  items1 = [],
  items2 = [],
  btnLabel,
  btnLink,
  homeLabel,
  homeLink,
  languages,
}: TNavigation): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()

  const homeCategory: TNavigationItem[] = homeLabel
    ? [
        {
          _uid: 'NavigationMobile-HomeCategory',
          component: componentTypes.NAVIGATION_ITEM,
          label: homeLabel,
          link: homeLink,
        },
      ]
    : []

  useEffect(() => {
    setIsOpen(false)
  }, [router])

  useEffect(() => {
    document.documentElement.style.overflow = isOpen ? 'hidden' : ''
  }, [isOpen])

  return (
    <div className={classNames(styles.wrapper, {[styles.isOpen]: isOpen})}>
      <div className={styles.header}>
        <Link link={homeLink}>
          <LogoStandaloneNavy title='Home' className={styles.logoSmall} />
          <LogoNavy title='Home' className={styles.logoMedium} />
        </Link>
        <CallToAction btnLabel={btnLabel} btnLink={btnLink} className={styles.callToActionMedium} />
        <button
          aria-hidden='true'
          tabIndex={-1}
          className={styles.close}
          onClick={() => setIsOpen(false)}
        >
          <CloseS />
        </button>
        <button
          aria-hidden='true'
          tabIndex={-1}
          className={styles.burger}
          onClick={() => setIsOpen(true)}
        >
          <MenuS />
        </button>
      </div>
      <div className={styles.items} aria-hidden='true'>
        {[homeCategory, items1, items2].map((items, index) => (
          <div key={index} className={styles.category}>
            {items.map((item) => {
              if (item.component === componentTypes.NAVIGATION_ITEM) {
                return <NavigationItem key={item._uid} {...item} className={styles.item} />
              } else if (item.component === componentTypes.NAVIGATION_ITEM_RICH) {
                return (
                  <div key={item._uid} className={styles.disclosure}>
                    <Disclosure>
                      {({open}) => (
                        <>
                          <Disclosure.Button className={styles.item}>
                            <span className={styles.label}>{item.label}</span>
                            <span className={classNames(styles.chevron, {[styles.isOpen]: open})}>
                              <SvgChevronDownXs />
                            </span>
                          </Disclosure.Button>
                          <Disclosure.Panel className={styles.panel}>
                            {item.items?.map((item) => {
                              if (item.component !== componentTypes.NAVIGATION_ITEM_RICH_LIST) {
                                return null
                              }

                              return (
                                <div key={item._uid} className={styles.column}>
                                  {item.heading ? (
                                    <p className={styles.heading}>{item.heading}</p>
                                  ) : null}
                                  <ul className={styles.list}>
                                    {(item.items || []).map((item) => {
                                      if (
                                        item.component ===
                                        componentTypes.NAVIGATION_ITEM_RICH_LIST_ITEM
                                      ) {
                                        return (
                                          <Link
                                            key={item._uid}
                                            className={styles.link}
                                            link={item.link}
                                          >
                                            {item.icon && item.icon.filename && (
                                              <span className={styles.icon} aria-hidden>
                                                <StoryblokImage src={item.icon.filename} alt='' />
                                              </span>
                                            )}
                                            <span className={styles.label}>{item.label}</span>
                                          </Link>
                                        )
                                      }
                                      return null
                                    })}
                                  </ul>
                                </div>
                              )
                            })}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                )
              }

              return null
            })}
          </div>
        ))}
        {languages && (
          <div className={styles.languages}>
            <LanguageSwitcher languages={languages} />
          </div>
        )}
        <CallToAction btnLabel={btnLabel} btnLink={btnLink} className={styles.callToActionSmall} />
      </div>
    </div>
  )
}

export default MobileNavigation
