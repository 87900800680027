import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgArrowUpS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M3.74 5.882a.7.7 0 000 1.054.876.876 0 001.157 0l2.285-2.082v8.4c0 .412.366.746.818.746.452 0 .818-.334.818-.745V4.854l2.285 2.082a.876.876 0 001.157 0 .7.7 0 000-1.054L8 2 3.74 5.882z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgArrowUpS
