import classNames from 'classnames'
import {render} from 'storyblok-rich-text-react-renderer'

import {TMasonryCard} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import BoxShadow from '@/components/BoxShadow'
import ButtonTertiary from '@/components/ButtonTertiary'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './MasonryCard.module.scss'

const MasonryCard = ({
  image,
  paragraph,
  link,
  linkLabel,
  title,
  withShadow,
  fillHeight,
}: TMasonryCard): JSX.Element => {
  if (!image || !paragraph) return <div />

  return (
    <div className={classNames(styles.wrapper, {[styles.fillHeight]: fillHeight})}>
      {link && <BoxShadow className={styles.shadow} />}
      <div className={classNames(styles.card, {[styles.withShadow]: withShadow})}>
        {image && (
          <StoryblokImage
            src={image.filename}
            alt={image.alt || ''}
            loading='lazy'
            className={styles.image}
            sizes={{md: 50, lg: 20}}
          />
        )}
        {title && <p className={styles.title}>{title}</p>}
        {paragraph && (
          <p className={classNames(styles.paragraph, {[styles.withTitle]: title})}>
            {render(paragraph, unwrapRenderParagraph)}
          </p>
        )}
        {link && (
          <div className={styles.linkWrapper}>
            <ButtonTertiary className={styles.link} label={linkLabel} link={link} />
          </div>
        )}
      </div>
    </div>
  )
}

export default MasonryCard
