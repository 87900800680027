import {componentTypes} from '@/common/enums'
import {TTestimonialItem, TTestimonialReference} from '@/common/types'

export function convertTestimonialReferenceToTestimonialItem(
  props: TTestimonialReference
): TTestimonialItem {
  let extendedProps = {}

  if (props.reference && typeof props.reference !== 'string') {
    extendedProps = props.reference.content
  }

  return {
    component: componentTypes.TESTIMONIAL_ITEM,
    _uid: props._uid,
    _editable: props._editable,
    className: props.className,
    ...extendedProps,
  }
}
