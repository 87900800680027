import {gsap} from 'gsap'
import {DrawSVGPlugin} from 'gsap/dist/DrawSVGPlugin'
import React, {useEffect, useMemo, useRef} from 'react'

import {componentTypes} from '@/common/enums'
import {TTestimonialItem, TTestimonialReference} from '@/common/types'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './TestimonialItem.module.scss'
import {convertTestimonialReferenceToTestimonialItem} from './TestimonialItemHelpers'

gsap.registerPlugin(DrawSVGPlugin)

type TCircle = {
  classNameCircle?: string
  isLoader?: boolean
  isActive?: boolean
  animationDuration?: number
}

const Circle = ({
  classNameCircle,
  isLoader,
  isActive,
  animationDuration = 0,
  ...props
}: TCircle & React.SVGProps<SVGSVGElement>) => {
  const circle = useRef<SVGCircleElement>(null)
  const timeline = useMemo(() => gsap.timeline(), [])

  useEffect(() => {
    if (circle.current) {
      if (isLoader) {
        timeline.fromTo(
          circle.current,
          {drawSVG: '0% 0%'},
          {duration: animationDuration, drawSVG: '0% 100%', ease: 'linear', repeat: -1}
        )
      } else {
        timeline.fromTo(
          circle.current,
          {drawSVG: `0% 100%`},
          {duration: animationDuration, drawSVG: `100% 100%`, ease: 'linear', repeat: -1}
        )
      }
    }
  }, [])

  useEffect(() => {
    if (isActive) {
      timeline.restart()
    } else {
      timeline.pause().time(0)
    }
  }, [isActive])

  return (
    <svg viewBox='0 0 100 100' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle ref={circle} r='46' cx='50' cy='50' fill='transparent' className={classNameCircle} />
    </svg>
  )
}

const TestimonialItem = (props: TTestimonialItem | TTestimonialReference): JSX.Element => {
  const {testimonial, author, job, picture, isActive, animationDuration} =
    props.component === componentTypes.TESTIMONIAL_ITEM
      ? props
      : convertTestimonialReferenceToTestimonialItem(props)

  return (
    <div className={styles.container}>
      {testimonial && <p className={styles.testimonial}>&ldquo;{testimonial}&rdquo;</p>}
      {author && (
        <p className={styles.author}>
          {author}
          {job && <span className={styles.job}>{`, ${job}`}</span>}
        </p>
      )}
      {picture && (
        <div className={styles.picture}>
          <Circle
            className={styles.svg}
            isActive={isActive}
            animationDuration={animationDuration}
            classNameCircle={styles.circle}
          />
          <Circle
            className={styles.svg}
            isActive={isActive}
            animationDuration={animationDuration}
            classNameCircle={styles.loader}
            isLoader
          />
          <StoryblokImage
            className={styles.img}
            alt={picture.alt}
            src={picture.filename}
            sizes={{sm: 30, md: 10, lg: 4}}
          />
        </div>
      )}
    </div>
  )
}

export default TestimonialItem
