import dynamic from 'next/dynamic'
import {useState} from 'react'

import {TVideo} from '@/common/types'
import StoryblokImage from '@/components/StoryblokImage'

import Shape from './Shape'
import Triangle from './Triangle'
import styles from './Video.module.scss'

const Player = dynamic(() => import('./Player'))

const Video = ({thumbnail, vimeoId}: TVideo): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  if (!thumbnail || !vimeoId) return <div />

  return (
    <section className={styles.section}>
      <div className={styles.background}>
        <Shape className={styles.shape} preserveAspectRatio='none' />
      </div>
      <button className={styles.thumbnail} aria-labelledby='Play' onClick={() => setIsOpen(true)}>
        <StoryblokImage src={thumbnail.filename} alt={thumbnail.alt} className={styles.image} />
        <div className={styles.gradient} />
        <div className={styles.button}>
          <Triangle className={styles.triangle} />
        </div>
      </button>
      {isOpen && <Player vimeoId={vimeoId} onClose={() => setIsOpen(false)} />}
    </section>
  )
}

export default Video
