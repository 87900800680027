import {TCtaDouble} from '@/common/types'
import Button from '@/components/Button'
import Circle from '@/components/CtaDouble/CtaDoubleHelpers'
import Link from '@/components/Link'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './CtaDouble.module.scss'

const CtaDouble = ({
  headingMarketplace,
  headingAcquisition,
  paragraphMarketplace,
  paragraphAcquisition,
  btnLabelMarketplace,
  btnLabelAcquisition,
  btnLinkMarketplace,
  btnLinkAcquisition,
  bgImageMarketplace,
  bgImageAcquisition,
}: TCtaDouble): JSX.Element => {
  const storyblokImageSizes = {lg: 50}

  return (
    <section className={styles.section}>
      {btnLinkMarketplace && (
        <Link link={btnLinkMarketplace} className={styles.marketplace}>
          <Circle />
          {bgImageMarketplace && (
            <StoryblokImage
              className={styles.backgroundImage}
              src={bgImageMarketplace.filename}
              alt={bgImageMarketplace.alt}
              sizes={storyblokImageSizes}
            />
          )}
          {headingMarketplace && <h2 className={styles.heading}>{headingMarketplace}</h2>}
          {paragraphMarketplace && <p className={styles.paragraph}>{paragraphMarketplace}</p>}
          {btnLabelMarketplace && btnLinkMarketplace && (
            <div className={styles.button}>
              <Button label={btnLabelMarketplace} isSecondary isMarketplace />
            </div>
          )}
        </Link>
      )}

      {btnLinkAcquisition && (
        <Link link={btnLinkAcquisition} className={styles.acquisition}>
          <Circle />
          {bgImageAcquisition && (
            <StoryblokImage
              className={styles.backgroundImage}
              src={bgImageAcquisition.filename}
              alt={bgImageAcquisition.alt}
              sizes={storyblokImageSizes}
            />
          )}
          {headingAcquisition && <h2 className={styles.heading}>{headingAcquisition}</h2>}
          {paragraphAcquisition && <p className={styles.paragraph}>{paragraphAcquisition}</p>}
          {btnLabelAcquisition && btnLinkAcquisition && (
            <div className={styles.button}>
              <Button label={btnLabelAcquisition} isSecondary isAcquisition />
            </div>
          )}
        </Link>
      )}
    </section>
  )
}

export default CtaDouble
