import {NODE_PARAGRAPH} from 'storyblok-rich-text-react-renderer'

import {TAlternativeUrls, TLanguageSwitcherOption, TStory, TStoryblokLink} from './types'

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export const unwrapRenderParagraph = {
  nodeResolvers: {
    [NODE_PARAGRAPH]: function nodeParagraph(children: React.ReactNode): JSX.Element {
      return <>{children}</>
    },
  },
}

export function addNonBreakingSpaces<T>(json: T): T {
  return JSON.parse(
    JSON.stringify(json)
      .replace(/\s([:!?])/gi, '\u00A0$1')
      .replace('e-commerce', 'e\u2060-\u2060commerce')
  )
}

export function getAlternativeUrls(story: TStory): TAlternativeUrls {
  let alternativeUrls: TAlternativeUrls = []

  const {lang, slug, alternatives} = story

  const isXDefault = (lang: string) => (lang === 'en' ? 'x-default' : lang)

  alternativeUrls = [
    {
      lang: isXDefault(lang),
      href: slug,
    },
  ].concat(
    alternatives.map(({lang, slug}) => {
      return {
        lang: isXDefault(lang),
        href: slug,
      }
    })
  )

  return alternativeUrls
}

export function getLanguages(story: TStory): TLanguageSwitcherOption[] {
  const alternativeLanguages: TLanguageSwitcherOption[] = story.alternatives.map(({lang, slug}) => {
    return {
      label: lang,
      value: slug,
    }
  })

  const activeLanguage: TLanguageSwitcherOption = {
    label: story.lang,
    value: story.slug,
    isActive: true,
  }

  return alternativeLanguages.concat([activeLanguage])
}

export function isValidLink(link?: TStoryblokLink): boolean {
  if (!link) return false

  const hasExternalLink = Boolean(link.linktype === 'url' && link.url !== '')
  const hasInternalLink = Boolean(link.linktype === 'story' && link.id)

  return hasExternalLink || hasInternalLink
}
