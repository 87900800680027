import classNames from 'classnames'
import {render} from 'storyblok-rich-text-react-renderer'

import {TSplitListWithImageV2} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'
import Button from '@/components/Button'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './SplitListWithImageV2.module.scss'

const SplitListWithImageV2 = ({
  heading,
  paragraph,
  btnLabel,
  btnLink,
  image,
  isFlipped,
  theme,
}: TSplitListWithImageV2): JSX.Element => {
  return (
    <section
      className={classNames(
        styles.section,
        {[styles.hasBg]: theme === 'background'},
        {[styles.hasNoBg]: theme !== 'background'},
        {[styles.isFlipped]: isFlipped}
      )}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          {heading && <h2 className={styles.heading}>{render(heading)}</h2>}

          {paragraph && (
            <p className={styles.paragraph}>{render(paragraph, unwrapRenderParagraph)}</p>
          )}

          {btnLink && btnLabel && (
            <div className={styles.button}>
              <Button link={btnLink} label={btnLabel} />
            </div>
          )}
        </div>

        {image && image.filename && (
          <div className={styles.picture}>
            <div className={styles.innerpicture}>
              <StoryblokImage
                className={styles.image}
                src={image.filename}
                alt={image.alt}
                loading='lazy'
                sizes={{lg: 50}}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default SplitListWithImageV2
