import {ArrowDownS, ArrowLeftS, ArrowRightS, ArrowUpS} from '@/assets/specify'
import {TSlidersButton} from '@/common/types'

import styles from './SlidersButton.module.scss'

const SlidersButton = ({arrow, onClick, disabled}: TSlidersButton): JSX.Element => {
  return (
    <button className={styles.button} onClick={onClick} disabled={disabled}>
      {arrow === 'up' && <ArrowUpS title='Previous' className={styles.arrow} />}
      {arrow === 'down' && <ArrowDownS title='Next' className={styles.arrow} />}
      {arrow === 'left' && <ArrowLeftS title='Previous' className={styles.arrow} />}
      {arrow === 'right' && <ArrowRightS title='Next' className={styles.arrow} />}
    </button>
  )
}

export default SlidersButton
