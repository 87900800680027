import {TWithFullWidthImage} from '@/common/types'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './WithFullWidthImage.module.scss'

const WithFullWidthImage = ({
  paragraphLeft,
  paragraphRight,
  image,
}: TWithFullWidthImage): JSX.Element => {
  return (
    <section className={styles.section}>
      {(paragraphLeft || paragraphRight) && (
        <article className={styles.paragraphs}>
          {paragraphLeft && <p className={styles.paragraph}>{paragraphLeft}</p>}
          {paragraphRight && <p className={styles.paragraph}>{paragraphRight}</p>}
        </article>
      )}

      {image && (
        <div className={styles.picture}>
          <StoryblokImage
            className={styles.image}
            src={image.filename}
            alt={image.alt}
            isFullWidth
          />
        </div>
      )}
    </section>
  )
}

export default WithFullWidthImage
