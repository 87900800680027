import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgTestimonialsQuoteL({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={897}
      height={613}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        opacity={0.1}
        d='M0 408.667C0 521.52 91.692 613 204.806 613c113.115 0 204.807-91.48 204.807-204.333 0-111.466-89.498-202.027-200.693-204.226 51.709-50.592 122.528-81.845 200.693-81.845V0C183.394 0 0 182.971 0 408.667zM897 0C670.781 0 487.387 182.971 487.387 408.667 487.387 521.52 579.089 613 692.194 613 805.308 613 897 521.52 897 408.667c0-111.466-89.498-202.027-200.692-204.226 51.708-50.592 122.537-81.845 200.692-81.845V0z'
        fill='#fff'
      />
    </svg>
  )
}

export default SvgTestimonialsQuoteL
