import NextHead from 'next/head'
import {useRouter} from 'next/router'
import React from 'react'
import urljoin from 'url-join'

import {baseUrl, defaultLanguage} from '@/common/config'
import {THead} from '@/common/types'

const Head = ({
  title,
  description,
  thumbnail,
  locale,
  alternates = [],
  isNetlify = false,
}: THead): JSX.Element => {
  const router = useRouter()
  const canonical = urljoin(baseUrl, router.asPath.replace('index', ''))
  const siteTitle = 'Shoppingfeed'
  const metaTitle = title || ''
  const metaThumbnail = thumbnail || urljoin(baseUrl, 'thumbnail.jpg')

  // const businessSchema = {};

  return (
    <NextHead>
      <meta charSet='utf-8' />
      <meta httpEquiv='x-ua-compatible' content='ie=edge' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0, shrink-to-fit=no' />
      {isNetlify && <meta name='robots' content='noindex' />}

      {/* Tell the browser to never restore the scroll position on load */}
      <script dangerouslySetInnerHTML={{__html: `history.scrollRestoration = "manual"`}} />

      {alternates.map(({lang, href}, index) => {
        if (!lang || !href) return null

        return (
          <link
            key={`${index}_${lang}`}
            rel='alternate'
            hrefLang={lang}
            href={urljoin(baseUrl, href.replace('index', ''))}
          />
        )
      })}

      <link
        rel='preload'
        as='font'
        crossOrigin=''
        type='font/woff2'
        href='/fonts/Montserrat-Book.woff2'
      />
      <link
        rel='preload'
        as='font'
        crossOrigin=''
        type='font/woff2'
        href='/fonts/Montserrat-Bold.woff2'
      />
      <link
        rel='preload'
        as='font'
        crossOrigin=''
        type='font/woff2'
        href='/fonts/Montserrat-Medium.woff2'
      />

      <meta name='theme-color' content='#ffffff' />

      {/* https://github.com/audreyfeldroy/favicon-cheat-sheet */}
      {/* <link rel='mask-icon' href='/favicon.svg' color='#6FCF97' /> */}
      <link rel='apple-touch-icon-precomposed' href='/apple-touch-icon-152x152.png' />
      <link rel='shortcut icon' sizes='196x196' href='/favicon-196x196.png' />

      {/* <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{__html: JSON.stringify(businessSchema)}}
      /> */}

      <meta property='og:type' content='website' />
      <meta property='og:locale' content={locale ?? defaultLanguage} />
      <meta property='og:site_name' content={siteTitle} />

      {/* Recommended size: 1200x600px  */}
      <meta property='og:image' content={metaThumbnail} />
      <meta property='og:image:secure_url' content={metaThumbnail} />
      <meta name='twitter:card' content='summary_large_image' />

      <meta name='twitter:image:alt' content={metaTitle} />

      <link rel='canonical' href={canonical} />
      <meta property='og:url' content={canonical} />

      <title>{metaTitle}</title>
      <meta property='og:title' content={metaTitle} />

      <meta name='description' content={description} />
      <meta property='og:description' content={description} />

      <meta name='facebook-domain-verification' content='e8k6i0qwl9bjorwo35zkp889jspqcn' />
    </NextHead>
  )
}

export default Head
