import {render} from 'storyblok-rich-text-react-renderer'

import {TLogoList, TLogoListItem} from '@/common/types'
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage'

import styles from './LogoList.module.scss'

const LogoList = ({heading, items = []}: TLogoList): JSX.Element => {
  return (
    <section className={styles.section}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {items &&
            items.map(
              ({_uid, logo, text}: TLogoListItem): JSX.Element => (
                <div key={_uid} className={styles.logoListItem}>
                  <div className={styles.logo}>
                    <div className={styles.innerlogo}>
                      {logo && logo.filename && (
                        <StoryblokImage src={logo.filename} alt={logo.alt} />
                      )}
                    </div>
                  </div>
                  <div className={styles.separator}></div>
                  <div className={styles.text}>{render(text)}</div>
                </div>
              )
            )}
        </div>
      </div>
    </section>
  )
}

export default LogoList
