import * as React from 'react'

function BgCorner({className}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={className}
      width='694'
      height='694'
      viewBox='0 0 694 694'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 694H694V0C693.844 383.312 383.189 694 0 694Z'
        fill='white'
      />
    </svg>
  )
}

export default BgCorner
