import {componentTypes} from '@/common/enums'
import {TChannelsV2Empty} from '@/common/types'
import ButtonStoryblok from '@/components/ButtonStoryblok'

import styles from './ChannelsV2Empty.module.scss'

const ChannelsV2Empty = ({heading, description, buttons = []}: TChannelsV2Empty): JSX.Element => {
  return (
    <div className={styles.container}>
      {heading && <h3 className={styles.heading}>{heading}</h3>}
      {description && <p className={styles.description}>{description}</p>}
      {buttons.map((props) => {
        if (props.component !== componentTypes.BUTTON_STORYBLOK) return null
        return (
          <div key={props._uid} className={styles.buttons}>
            <ButtonStoryblok {...props} />
          </div>
        )
      })}
    </div>
  )
}

export default ChannelsV2Empty
