import {componentTypes} from '@/common/enums'
import {TCardSliderItem, TResourceCategories, TResourceItem} from '@/common/types'

type GetTranslatedCategory = (options: {
  category?: string
  resourceCategories?: TResourceCategories
  locale?: string
}) => string | undefined

const getTranslatedCategory: GetTranslatedCategory = ({category, resourceCategories, locale}) => {
  if (!category) {
    return undefined
  } else if (
    resourceCategories &&
    locale &&
    resourceCategories[category] &&
    resourceCategories[category][locale]
  ) {
    return resourceCategories[category][locale]
  } else {
    return category
  }
}

export function convertResourceItemToCardSliderItem({
  _uid,
  _editable,
  className,
  reference,
  resourceCategories,
  locale,
}: TResourceItem): TCardSliderItem {
  let convertedProps: Partial<TCardSliderItem> = {}

  if (reference && typeof reference !== 'string') {
    const {title, featuredImage, category, link} = reference.content
    convertedProps = {
      heading: title,
      image: featuredImage,
      category: getTranslatedCategory({category, resourceCategories, locale}),
      link,
    }
  }

  return {
    _uid,
    _editable,
    className,
    component: componentTypes.CARD_SLIDER_ITEM,
    ...convertedProps,
  }
}
