import classNames from 'classnames'

import {TNavigationItem} from '@/common/types'
import Link from '@/components/Link'

import styles from './NavigationItem.module.scss'

const NavigationItem = ({label, link, className}: TNavigationItem): JSX.Element => (
  <Link label={label} link={link} className={classNames(className, styles.label)} />
)

export default NavigationItem
