/* eslint-disable no-irregular-whitespace */
import classNames from 'classnames'
import Marquee from 'react-fast-marquee'

import {TScrollingTitle} from '@/common/types'

import styles from './ScrollingTitle.module.scss'

const ScrollingTitle = ({
  className,
  heading,
  isDark,
  isAcquisition,
  isMarketplace,
  noPadding,
  size,
}: TScrollingTitle): JSX.Element => {
  const sharedClassName = classNames(className, styles.section, {
    [styles.dark]: isDark,
    [styles.marketplace]: isMarketplace,
    [styles.acquisition]: isAcquisition,
    [styles.noPadding]: noPadding,
    [styles.medium]: size === 'm',
    [styles.small]: size === 's',
  })

  return (
    <section className={sharedClassName}>
      <Marquee className={styles.heading} direction='right' speed={80} gradient={false}>
        {`${heading} ${heading} ${heading} ${heading} `}
      </Marquee>
    </section>
  )
}

export default ScrollingTitle
