import * as React from 'react'

function BgCorner({className}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      className={className}
      width='1'
      height='1'
      viewBox='0 0 1 1'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <clipPath id='HeroSimpleClipPath' clipPathUnits='objectBoundingBox'>
        <path
          d='M0 0.5C0 0.223858 0.223858 0 0.5 0H1V1H0.5C0.223858 1 0 0.776142 0 0.5V0.5Z'
          fill='none'
        />
      </clipPath>
    </svg>
  )
}

export default BgCorner
