import {TFooterItem} from '@/common/types'
import Link from '@/components/Link'

import styles from './FooterItem.module.scss'

const FooterItem = ({label, link}: TFooterItem): JSX.Element => {
  return <Link className={styles.link} label={label} link={link} />
}

export default FooterItem
