import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import {random} from 'lodash'
import {useEffect, useRef} from 'react'

import {TMasonryCards} from '@/common/types'
import DynamicComponent from '@/components/DynamicComponent'

import styles from './MasonryCards.module.scss'

gsap.registerPlugin(ScrollTrigger)

const MasonryCards = ({subheading, heading, cards = []}: TMasonryCards): JSX.Element => {
  const headerRef = useRef<HTMLDivElement>(null)
  const cardsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (cardsRef.current && headerRef.current) {
      // setTimeout is needed to let GSAP calculate the appropriate size
      setTimeout(() => {
        ScrollTrigger.create({
          trigger: cardsRef.current,
          start: '10% bottom',
          end: 'bottom 25%',
          toggleClass: {
            className: styles.isTransparent,
            targets: headerRef.current,
          },
        })
      }, 100)
    }
  }, [])

  return (
    <section className={styles.wrapper}>
      <div className={styles.header} ref={headerRef}>
        {subheading && <p className={styles.subheading}>{subheading}</p>}
        {heading && (
          <h2 className={classNames(styles.heading, {[styles.noSubheading]: !subheading})}>
            {heading}
          </h2>
        )}
      </div>
      <div className={styles.cards} ref={cardsRef}>
        {cards.map((props, index) => (
          <div
            key={props._uid}
            className={styles.card}
            style={{
              gridRowStart: index + 1,
              ['--grid-column-start-md' as string]: gsap.utils.wrap([2, 5], index),
              ['--grid-column-start-lg' as string]: gsap.utils.wrap([3, 10, 4, 8, 2, 7], index),
              ['--translate-y' as string]: index === 0 ? '' : `translateY(-${random(10, 50)}%)`,
            }}
          >
            <DynamicComponent key={props._uid} {...props} />
          </div>
        ))}
      </div>
      <div className={styles.spacer} />
    </section>
  )
}

export default MasonryCards
