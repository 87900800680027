import classNames from 'classnames'

import {TButton} from '@/common/types'
import BoxShadow from '@/components/BoxShadow'
import Link from '@/components/Link'

import styles from './Button.module.scss'

const WithShadow = ({children}: {children: React.ReactNode}): JSX.Element => (
  <>
    <BoxShadow className={styles.shadow} />
    {children}
  </>
)

const Button = ({
  className,
  label,
  link,
  isSmall,
  isSecondary,
  isMarketplace,
  isAcquisition,
  onClick = () => undefined,
  ...props
}: TButton): JSX.Element => {
  const sharedClassName = classNames(className, styles.button, {
    [styles.buttonSmall]: isSmall,
    [styles.isSecondary]: isSecondary,
    [styles.marketplace]: isMarketplace,
    [styles.acquisition]: isAcquisition,
  })

  if (link) {
    return (
      <div className={styles.container}>
        {label && (
          <WithShadow>
            <Link label={label} link={link} className={sharedClassName} />
          </WithShadow>
        )}
      </div>
    )
  }

  return (
    <button {...props} className={styles.container} onClick={onClick}>
      {label && (
        <WithShadow>
          <span className={sharedClassName}>{label}</span>
        </WithShadow>
      )}
    </button>
  )
}

export default Button
