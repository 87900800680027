import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function Shape({title, titleId, ...props}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width='1152'
      height='800'
      viewBox='0 0 1152 800'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M1152 400C1152 179.086 972.914 0 752 0H-177.949V800H752C972.914 800 1152 620.914 1152 400Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Shape
