import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgArrowRightS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M11.366 3.834a.8.8 0 00-1.132 1.132L12.47 7.2H1.6a.8.8 0 000 1.6h10.869l-2.235 2.234a.8.8 0 001.132 1.132l3.6-3.6.565-.566-.565-.565-3.6-3.6z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgArrowRightS
