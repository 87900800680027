import {createContext, ReactNode, useContext} from 'react'

import {defaultLanguage} from '@/common/config'
import {translationNamespaces} from '@/common/enums'
import {TTranslations} from '@/common/types'

type TranslationsContext = undefined | {translations?: TTranslations; locale?: string}

const TranslationsContext = createContext<TranslationsContext>(undefined)

type TranslationsProvider = (props: {
  children: ReactNode
  translations: TTranslations
  locale: string
}) => JSX.Element

export const TranslationsProvider: TranslationsProvider = ({children, translations, locale}) => (
  <TranslationsContext.Provider value={{translations, locale}}>
    {children}
  </TranslationsContext.Provider>
)

export const useTranslations = (): {
  t: (namespace: translationNamespaces, value: string) => string
  getName: (namespace: translationNamespaces, value: string) => string
} => {
  const context = useContext(TranslationsContext)
  const {translations, locale = defaultLanguage} = context || {}

  return {
    t: (namespace: translationNamespaces, value: string): string => {
      if (translations && translations[namespace]) {
        const defaultTranslation = translations[namespace][defaultLanguage].find(
          (i) => i.value === value
        )

        if (defaultTranslation) {
          const namespaceTranslations = translations[namespace][locale]
          if (namespaceTranslations) {
            const translation = namespaceTranslations.find(
              (i) => i.name === defaultTranslation.name
            )
            if (translation) {
              return translation.value
            }
          }
        }
      }

      return value
    },
    getName: (namespace: translationNamespaces, value: string): string => {
      if (translations && translations[namespace]) {
        const defaultTranslation = translations[namespace][defaultLanguage].find(
          (i) => i.value === value
        )

        if (defaultTranslation) {
          return defaultTranslation.name
        }
      }

      return value
    },
  }
}
