// import {yupResolver} from '@hookform/resolvers/yup'
// import axios from 'axios'
// import classNames from 'classnames'
// import gsap from 'gsap'
import {useEffect} from 'react'
// import {useForm} from 'react-hook-form'
import {render} from 'storyblok-rich-text-react-renderer'

// import * as yup from 'yup'
import {THeroHomeV2, TImage} from '@/common/types'
import MarqueeV2 from '@/components/MarqueeV2/MarqueeV2'
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage'

import styles from './HeroHomeV2.module.scss'

export type TFormData = {
  firstname?: string
  lastname?: string
  phone?: string
  email?: string
}

type hbsptCreate = {
  portalId?: string
  formId?: string
  target?: string
}

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: ({portalId, formId, target}: hbsptCreate) => void
      }
    }
  }
}

const HeroHomeV2 = ({heading, subtitle, images = [], formID = ''}: THeroHomeV2): JSX.Element => {
  // const confirmRef = useRef(null) as React.MutableRefObject<HTMLDivElement | null>

  // const validationSchema = yup.object().shape({
  //   firstname: yup.string().required('Your firtname is required'),
  //   lastname: yup.string().required('Your lastname is required'),
  //   phone: yup
  //     .string()
  //     .required('Phone is required')
  //     .matches(
  //       // eslint-disable-next-line no-useless-escape
  //       /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  //       'Phone number is invalid'
  //     ),
  //   email: yup.string().required('Email Required').email('A valid email address is required'),
  // })
  // const formOptions = {resolver: yupResolver(validationSchema)}

  // const {
  //   register,
  //   handleSubmit,
  //   formState: {errors},
  // } = useForm(formOptions)

  // const onSubmit = (data: TFormData) => {
  //   const {firstname, lastname, phone, email} = data

  //   axios
  //     .post(
  //       'https://api.hsforms.com/submissions/v3/integration/submit/19665323/5eb15aaf-48ac-43c9-b155-2ae2a9b34b19',
  //       {
  //         fields: [
  //           {
  //             objectTypeId: '0-1',
  //             name: 'firstname',
  //             value: firstname,
  //           },
  //           {
  //             objectTypeId: '0-1',
  //             name: 'lastname',
  //             value: lastname,
  //           },
  //           {
  //             objectTypeId: '0-1',
  //             name: 'phone',
  //             value: phone,
  //           },
  //           {
  //             objectTypeId: '0-1',
  //             name: 'email',
  //             value: email,
  //           },
  //         ],
  //         context: {
  //           pageUri: 'shoppingfeed.com',
  //           pageName: 'Index FR',
  //         },
  //       }
  //     )
  //     .then(function () {
  //       const tl = gsap.timeline()
  //       tl.set(confirmRef.current, {display: 'flex'})
  //       tl.to(confirmRef.current, {opacity: 1, duration: 0.1})
  //     })
  //     .catch(function (error) {
  //       // eslint-disable-next-line no-console
  //       console.log(error)
  //       // const tl = gsap.timeline()
  //       // tl.set(errorRef.current, {display: 'flex'})
  //       // tl.to(errorRef.current, {opacity: 1, duration: 0.1})
  //     })
  // }

  useEffect(() => {
    if (formID !== '') {
      const hubspotFormJs = document.createElement('script')
      hubspotFormJs.src = 'https://js.hsforms.net/forms/v2.js'
      document.body.appendChild(hubspotFormJs)

      hubspotFormJs.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: '19665323',
            formId: formID,
            target: '#hubspotForm',
          })
        }
      })
    }
  }, [])

  return (
    <section className={styles.section}>
      <div className={styles.shapes}>
        <div className={styles.shapeleft}></div>
        <div className={styles.shaperight}></div>
      </div>
      <div className={styles.container}>
        <div className={styles.textcontent}>
          {heading && <h2 className={styles.heading}>{render(heading)}</h2>}
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          {images && images.length > 0 && (
            <div className={styles.marquee}>
              <MarqueeV2>
                <div className={styles.innermarquee}>
                  {images.map(({_uid, image, component}: TImage) => {
                    if (!image || typeof image === 'string' || component !== 'image') return null
                    return (
                      <div key={_uid} className={styles.item}>
                        <StoryblokImage src={image.filename} alt={image.alt} />
                      </div>
                    )
                  })}
                </div>
              </MarqueeV2>
            </div>
          )}
        </div>

        {formID !== '' && (
          <div className={styles.formcontent}>
            <div className={styles.formwrapper}>
              <div className={styles.formcontainer}>
                <div className={styles.formtitle}>
                  <p>Demandez une démo</p>
                </div>

                {/* <div className={styles.formbonus}>
                <svg viewBox='0 0 43 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    opacity='.4'
                    d='m10.958 7.759.166 10.186a3.396 3.396 0 0 0 3.45 3.34l20.372-.33a3.396 3.396 0 0 0 3.34-3.45l-.165-10.187a3.396 3.396 0 0 0-3.45-3.34l-20.372.33a3.396 3.396 0 0 0-3.34 3.45Z'
                    fill='#6B30F6'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M30.479 14.885c.584.391.741 1.182.35 1.767l-8.196 10.52-2.117-1.416 8.196-10.52a1.273 1.273 0 0 1 1.767-.35Z'
                    fill='#6B30F6'
                  />
                  <circle
                    cx='9.399'
                    cy='16.275'
                    r='3.396'
                    transform='rotate(-.93 9.399 16.275)'
                    fill='#6B30F6'
                  />
                  <path
                    d='m14.657 26.378.014.849 5.458-2.975a2.464 2.464 0 1 1 2.697 4.125l-6.778 3.748a3.384 3.384 0 0 1-1.29.5l.064 3.94a1.698 1.698 0 0 1-1.67 1.724l-6.79.11a1.698 1.698 0 0 1-1.726-1.67l-.165-10.186a5.094 5.094 0 1 1 10.186-.165Z'
                    fill='#6B30F6'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.53 11.064a1.273 1.273 0 0 1 1.253-1.294l13.582-.22a1.273 1.273 0 0 1 .041 2.547l-13.581.22a1.273 1.273 0 0 1-1.294-1.253ZM16.613 16.158a1.273 1.273 0 0 1 1.253-1.294l6.79-.11a1.273 1.273 0 1 1 .042 2.546l-6.79.11a1.273 1.273 0 0 1-1.295-1.252Z'
                    fill='#6B30F6'
                  />
                </svg>

                <p>{`BONUS : recevez la liste des 150 questions pour votre Appel d'Offres Marketplaces`}</p>
              </div> */}

                {/* <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={classNames(styles.inputcontainer, styles.inputfirstname)}>
                  <input
                    placeholder={'Votre Prénom*'}
                    className={classNames({[styles.errorinput]: errors.firstname})}
                    {...register('firstname', {required: true})}
                  />
                  {errors.firstname && (
                    <div className={styles.error}>{errors.firstname?.message}</div>
                  )}
                </div>

                <div className={classNames(styles.inputcontainer, styles.inputlastname)}>
                  <input
                    placeholder={'Votre Nom*'}
                    className={classNames({
                      [styles.errorinput]: errors.lastname,
                    })}
                    {...register('lastname', {required: true})}
                  />
                  {errors.lastname && (
                    <div className={styles.error}>{errors.lastname?.message}</div>
                  )}
                </div>

                <div className={classNames(styles.inputcontainer, styles.inputphone)}>
                  <input
                    placeholder={'Votre Numéro de Téléphone*'}
                    className={classNames({
                      [styles.errorinput]: errors.phone,
                    })}
                    {...register('phone', {required: true})}
                  />
                  {errors.phone && <div className={styles.error}>{errors.phone?.message}</div>}
                </div>

                <div className={classNames(styles.inputcontainer, styles.inputemail)}>
                  <input
                    placeholder={'Votre email*'}
                    className={classNames({
                      [styles.errorinput]: errors.email,
                    })}
                    {...register('email', {required: true})}
                  />
                  {errors.email && <div className={styles.error}>{errors.email?.message}</div>}
                </div>

                <button className={styles.submitbutton} type='submit'>
                  Demander une démo
                </button>
              </form> */}

                <div id='hubspotForm' className={styles.hbsptform}></div>
              </div>

              {/* <div className={styles.formconfirm} ref={confirmRef}>
                <p className={styles.confirmtitle}>Merci pour votre demande !</p>
                <p className={styles.confirmparagraph}>
                  Veuillez verifier votre inbox, nous vous avons envoyé la liste !
                </p>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default HeroHomeV2
