import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgPlusXs({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={12}
      height={12}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.61 6.598L6.6 9.383a.58.58 0 01-.172.41.592.592 0 01-.424.186.58.58 0 01-.597-.597L5.391 6.6l-2.795-.01a.58.58 0 01-.41-.172A.592.592 0 012 5.994a.58.58 0 01.598-.597l2.796-.016h.008v-.002L5.4 2.596A.576.576 0 015.995 2a.58.58 0 01.597.598l.016 2.795 2.775-.004a.576.576 0 01.596.596.58.58 0 01-.597.597l-2.772.016z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgPlusXs
