import {render} from 'storyblok-rich-text-react-renderer'

import {TFooterAddress} from '@/common/types'
import {unwrapRenderParagraph} from '@/common/utils'

import styles from './FooterAddress.module.scss'

const FooterAddress = ({title, address}: TFooterAddress): JSX.Element => {
  return (
    <div className={styles.section}>
      {title && <p className={styles.title}>{title}</p>}
      {address && <p className={styles.address}>{render(address, unwrapRenderParagraph)}</p>}
    </div>
  )
}

export default FooterAddress
