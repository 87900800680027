import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React from 'react'

import {TVerticalSliderDots} from '@/common/types'

import styles from './VerticalSliderDots.module.scss'

gsap.registerPlugin(ScrollTrigger)

const VerticalSlider = ({
  className,
  length,
  active,
  isLight,
}: TVerticalSliderDots): JSX.Element | null => {
  if (typeof length !== 'number' || typeof active !== 'number') {
    return null
  }

  const array = Array.from(Array(length))

  return (
    <div className={classNames(className, {[styles.isLight]: isLight})}>
      {array.map((_, index) => (
        <div
          key={index}
          className={classNames(styles.container, {[styles.isActive]: active === index})}
        >
          <div key={index} className={styles.dot} />
        </div>
      ))}
    </div>
  )
}

export default VerticalSlider
