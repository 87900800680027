import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function Shape({title, titleId, ...props}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.2087 11.6058C23.9458 11.9743 23.9458 13.0261 23.2087 13.3947L3.44477 23.2766C2.77987 23.6091 1.99756 23.1256 1.99756 22.3822V2.61828C1.99756 1.8749 2.77987 1.3914 3.44477 1.72385L23.2087 11.6058Z'
        stroke='currentColor'
        strokeWidth='2'
      />
    </svg>
  )
}

export default Shape
