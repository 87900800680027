import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgLogoStandaloneNavy({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={40}
      height={40}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M20 40c11.045 0 20-8.955 20-20C40 8.954 31.045 0 20 0 8.954 0 0 8.954 0 20c0 11.045 8.954 20 20 20z'
        fill='#221F61'
      />
      <path
        d='M13.528 23.15l-.709-1.008 3.008-2.08c-2.236-1.432-3.654-3.936-3.654-6.613 0-4.315 3.512-7.827 7.827-7.827 4.063 0 7.496 3.18 7.811 7.228l.032.347-14.315 9.952zm6.488-16.3a6.607 6.607 0 00-6.598 6.599c0 2.425 1.385 4.693 3.527 5.842l9.606-6.677c-.425-3.26-3.228-5.764-6.535-5.764zm-.032 27.496c-4.063 0-7.496-3.18-7.81-7.228l-.032-.346 14.315-9.953.709 1.008-2.993 2.078c2.237 1.433 3.654 3.937 3.654 6.614-.016 4.316-3.528 7.827-7.843 7.827zM13.45 27.37c.425 3.26 3.228 5.748 6.535 5.748a6.607 6.607 0 006.599-6.599c0-2.425-1.386-4.692-3.528-5.842L13.45 27.37z'
        fill='#fff'
      />
    </svg>
  )
}

export default SvgLogoStandaloneNavy
