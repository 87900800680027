function Shape({className}: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width='703'
      height='512'
      viewBox='0 0 703 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMinYMid slice'
      className={className}
    >
      <path
        d='M0 256C0 114.615 114.615 0 256 0H703V512H256C114.615 512 0 397.385 0 256V256Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Shape
