import {TCardSliderItem} from '@/common/types'
import BoxShadow from '@/components/BoxShadow'
import Link from '@/components/Link'
import StoryblokImage from '@/components/StoryblokImage'

import styles from './CardSliderItem.module.scss'

const CardSliderItem = ({image, heading, category, link}: TCardSliderItem): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.border} />
      <BoxShadow className={styles.shadow} />
      <Link link={link} className={styles.container}>
        {image && (
          <picture className={styles.picture}>
            <StoryblokImage
              className={styles.image}
              src={image.filename}
              alt={image.alt}
              sizes={{md: 50, lg: 33}}
            />
          </picture>
        )}
        {category && <p className={styles.category}>{category}</p>}
        {heading && <h3 className={styles.heading}>{heading}</h3>}
      </Link>
    </div>
  )
}

export default CardSliderItem
