import classNames from 'classnames'

import {TTitleWithParagraph} from '@/common/types'

import styles from './TitleWithParagraph.module.scss'

const TitleWithParagraph = ({
  subheading,
  heading,
  paragraph,
  theme,
}: TTitleWithParagraph): JSX.Element => {
  const sharedClassName = classNames({
    [styles.isAcquisition]: theme === 'acquisition',
    [styles.isMarketplace]: theme === 'marketplace',
  })

  const HeadingTag = !subheading ? 'h2' : 'h3'

  return (
    <section className={styles.section}>
      {subheading && <h2 className={classNames(styles.heading, sharedClassName)}>{subheading}</h2>}
      {heading && (
        <HeadingTag className={classNames(styles.subHeading, sharedClassName)}>
          {heading}
        </HeadingTag>
      )}
      {paragraph && <p className={classNames(styles.paragraph, sharedClassName)}>{paragraph}</p>}
    </section>
  )
}

export default TitleWithParagraph
