import classNames from 'classnames'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
import React, {useEffect, useRef, useState} from 'react'

import {componentTypes} from '@/common/enums'
import {TVerticalSliderPrivate} from '@/common/types'
import DynamicComponent from '@/components/DynamicComponent'
import StoryblokImage from '@/components/StoryblokImage'
import VerticalSliderDots from '@/components/VerticalSlider/VerticalSliderDots'
import useIntersectionObserver from '@/hooks/useIntersectionObserver'

import styles from './VerticalSliderDesktop.module.scss'

gsap.registerPlugin(ScrollTrigger)

const VerticalSlider = ({isLight, slides = []}: TVerticalSliderPrivate): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const imagesRef = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(0)
  const entry = useIntersectionObserver(containerRef, {})

  const images = slides.map((slide) => {
    if (slide.component === componentTypes.VERTICAL_SLIDER_ITEM && slide.image) {
      return slide.image
    }
  })

  function handleEnter(props: ScrollTrigger): void {
    const trigger = props.trigger as HTMLElement | undefined
    if (trigger && trigger.dataset.index) {
      setActive(parseInt(trigger.dataset.index))
    }
  }

  function handleUpdate({progress, ...props}: ScrollTrigger): void {
    const trigger = props.trigger as HTMLElement | undefined
    if (trigger && trigger.dataset.index) {
      if (imagesRef.current) {
        const el = imagesRef.current.children[+trigger.dataset.index]
        let opacity = 1
        if (progress < 0.4) {
          opacity = progress * 2
        } else if (progress > 0.6) {
          opacity = 1 / progress - 1
        }
        gsap.set(el, {opacity})
      }
    }
  }

  // Enforce ScrollTrigger to refresh
  // To prevent some layout shifts
  // Making the start/end positions incorrect
  useEffect(() => {
    if (entry?.isIntersecting) {
      ScrollTrigger.refresh()
    }
  }, [entry])

  useEffect(() => {
    if (containerRef.current) {
      const slides = Array.from(containerRef.current.children)
      const tweens: gsap.core.Tween[] = []

      slides.forEach((node, index) => {
        // Do not add ScrollTrigger on dots and images
        if (index === 0 || index === slides.length - 1) return

        const text = node.children[0]

        if (!text) return

        tweens.push(
          gsap.fromTo(
            text,
            {opacity: 0, zIndex: 2},
            {
              opacity: 1,
              zIndex: 2,
              duration: 2,
              yoyo: true,
              repeat: 1,
              scrollTrigger: {
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0.5,
                trigger: text,
                onEnter: handleEnter,
                onEnterBack: handleEnter,
                onUpdate: handleUpdate,
              },
            }
          )
        )
      })

      return () => {
        tweens.forEach((tween) => {
          if (tween.scrollTrigger) tween.scrollTrigger.kill()
          tween.kill()
        })
      }
    }
  }, [])

  return (
    <section className={classNames(styles.section, {[styles.isLight]: isLight})}>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.dotsWrapper}>
          <div className={styles.dots}>
            <VerticalSliderDots length={slides.length} active={active} isLight={isLight} />
          </div>
        </div>
        {slides.map((props, index) => {
          if (props.component !== componentTypes.VERTICAL_SLIDER_ITEM) return null
          return (
            <div key={props._uid} className={styles.slide}>
              <div className={styles.content} data-index={index}>
                <DynamicComponent {...props} isLight={isLight} index={index + 1} />
              </div>
            </div>
          )
        })}
        <div className={styles.images} ref={imagesRef}>
          {slides.map((props, index) => {
            if (props.component !== componentTypes.VERTICAL_SLIDER_ITEM) return null
            const image = images[index]
            return (
              image && (
                <StoryblokImage
                  key={props._uid}
                  className={styles.image}
                  src={image.filename}
                  alt={image.alt}
                  sizes={{lg: 50}}
                />
              )
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default VerticalSlider
