import classNames from 'classnames'

import {ArrowRightXs} from '@/assets/specify'
import {TButtonSecondary} from '@/common/types'
import Link from '@/components/Link'

import styles from './ButtonSecondary.module.scss'

const ButtonSecondary = ({
  className,
  label,
  link,
  hasIcon,
  onClick = () => undefined,
  ...props
}: TButtonSecondary): JSX.Element => {
  const sharedClassName = classNames(className, styles.button, {
    [styles.marketplace]: props.isMarketplace,
    [styles.rightIcon]: props.hasRightIcon,
  })
  if (link) {
    return (
      <div>
        {label && link && (
          <Link link={link} className={sharedClassName}>
            {hasIcon && <ArrowRightXs className={styles.icon} />} {label}
          </Link>
        )}
      </div>
    )
  }

  return (
    <div>
      {label && (
        <button {...props} className={sharedClassName} onClick={onClick}>
          {hasIcon && <ArrowRightXs className={styles.icon} />} {label}
        </button>
      )}
    </div>
  )
}

export default ButtonSecondary
