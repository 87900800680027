import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgCheckS({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={16}
      height={16}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d='M14.25 4.875a.89.89 0 01-.273.629l-7 7a.848.848 0 01-.602.246.866.866 0 01-.629-.246l-3.5-3.5A.866.866 0 012 8.375c0-.492.383-.875.875-.875.219 0 .438.11.602.273l2.898 2.872 6.371-6.372A.89.89 0 0113.375 4c.465 0 .875.383.875.875z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgCheckS
