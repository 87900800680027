import {TNavigation} from '@/common/types'
import Desktop from '@/components/NavigationDesktop'
import Mobile from '@/components/NavigationMobile'

import styles from './Navigation.module.scss'

const Navigation = (props: TNavigation): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Mobile {...props} />
      <Desktop {...props} />
    </div>
  )
}

export default Navigation
