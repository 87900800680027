import gsap, {Power4} from 'gsap'
import React, {useEffect, useRef, useState} from 'react'

import {componentTypes} from '@/common/enums'
import {TIndustryCards} from '@/common/types'

import DynamicComponent from '../DynamicComponent'
import styles from './IndustryCards.module.scss'

const IndustryCards = ({categories}: TIndustryCards): JSX.Element => {
  const [active, setActive] = useState(0)
  const backgroundRef = useRef<HTMLDivElement>(null)
  const cardsRef = useRef<HTMLUListElement>(null)

  function handleClick(index: number) {
    if (cardsRef.current) {
      const tl = gsap.timeline()
      tl.to(cardsRef.current, {alpha: 0, duration: 0.2, ease: Power4.easeInOut}).call(setActive, [
        index,
      ])
    }
  }

  useEffect(() => {
    if (backgroundRef.current) {
      const background = backgroundRef.current
      const buttonsParent = background.parentElement
      const cards = cardsRef.current || null
      if (buttonsParent) {
        const children = (Array.from(buttonsParent.children) || []).filter((_, i) => i !== 0)
        const activeChild = children[active]
        if (activeChild) {
          const width = activeChild.clientWidth + 2
          const prevButtons = children.filter((_, i) => i < active)
          const x = prevButtons.reduce((width, btn) => width + btn.clientWidth, 0) - 1
          const tl = gsap.timeline()
          tl.to(backgroundRef.current, {width, x, ease: Power4.easeInOut, duration: 0.25}).to(
            cards,
            {
              alpha: 1,
              duration: 0.4,
              ease: Power4.easeInOut,
            }
          )
        }
      }
    }
  }, [active, categories])

  if (!categories) return <div />

  return (
    <section className={styles.section}>
      <div className={styles.buttons}>
        <div ref={backgroundRef} className={styles.background} />
        {categories.map((category, index) => {
          if (category.component !== componentTypes.INDUSTRY_CARDS_CATEGORY) return null
          const {heading} = category
          return (
            <button
              key={index}
              onClick={() => handleClick(index)}
              className={styles.button}
              disabled={index === active}
            >
              {heading}
            </button>
          )
        })}
      </div>
      <ul ref={cardsRef} className={styles.cards}>
        {categories.map((category, index) => {
          if (category.component !== componentTypes.INDUSTRY_CARDS_CATEGORY) return null
          if (index !== active) return null
          const {cards = []} = category
          return (
            <React.Fragment key={category._uid}>
              {cards.map((props) => {
                if (props.component !== componentTypes.INDUSTRY_CARD) return null
                return (
                  <li key={props._uid} className={styles.card}>
                    <DynamicComponent {...props} />
                  </li>
                )
              })}
            </React.Fragment>
          )
        })}
      </ul>
    </section>
  )
}

export default IndustryCards
