import classNames from 'classnames'

import {TCtaSimple} from '@/common/types'
import Button from '@/components/Button'
import ButtonSecondary from '@/components/ButtonSecondary'
import Shape from '@/components/CtaSimple/Shape'

import styles from './CtaSimple.module.scss'

const CtaSimple = ({heading, btnLabel, btnLink, theme}: TCtaSimple): JSX.Element => {
  const isAcquisition = theme === 'acquisition'
  const isMarketplace = theme === 'marketplace'

  const ButtonComponent = isAcquisition || isMarketplace ? ButtonSecondary : Button

  return (
    <section
      className={classNames(styles.section, {
        [styles.isAcquisition]: isAcquisition,
        [styles.isMarketplace]: isMarketplace,
      })}
    >
      <div className={styles.container}>
        <Shape className={styles.shape} />
        {heading && <h2 className={styles.heading}>{heading}</h2>}
        {btnLabel && btnLink && (
          <div className={styles.button}>
            <ButtonComponent label={btnLabel} link={btnLink} isMarketplace={isMarketplace} />
          </div>
        )}
      </div>
    </section>
  )
}

export default CtaSimple
