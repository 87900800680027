import * as React from 'react'
interface SVGRProps {
  title?: string
  titleId?: string
}

function SvgChevronDownXs({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps): JSX.Element {
  return (
    <svg
      width={12}
      height={12}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.646 3.646a.5.5 0 01.708 0L6 7.293l3.646-3.647a.5.5 0 01.708.708L6 8.707 1.646 4.354a.5.5 0 010-.708z'
        fill='#221F61'
      />
    </svg>
  )
}

export default SvgChevronDownXs
