import {useEffect, useMemo, useState} from 'react'
import Select from 'react-select'

import {WizardLogo as Logo} from '@/assets/specify'
import {defaultLanguage} from '@/common/config'
import {componentTypes} from '@/common/enums'
import {TStoryblokLink, TWizard} from '@/common/types'
import {notUndefined} from '@/common/utils'

import Button from '../Button'
import styles from './Wizard.module.scss'
import WizardBackground from './wizard-background.svg'

const selectSharedOptions = {
  className: styles.select,
  classNamePrefix: 'react-select',
  components: {
    IndicatorSeparator: () => null,
    // Input: () => null,
  },
}

const Wizard = ({
  heading,
  paragraph = '',
  callToAction,
  categories = [],
  marketplaceLabel,
  acquisitionLabel,
  locale,
}: TWizard): JSX.Element => {
  const [activeCategory, setActiveCategory] = useState<{
    marketplace?: TStoryblokLink
    acquisition?: TStoryblokLink
  }>()
  const [activeChannel, setActiveChannel] = useState<'marketplace' | 'acquisition'>()

  const splittedParagraph = paragraph.split('__')

  const categoryOptions = useMemo(
    () =>
      categories
        .map((props) => {
          if (props.component !== componentTypes.WIZARD_OPTION) return
          return {
            label: props.label,
            value: {marketplace: props.marketplaceLink, acquisition: props.acquisitionLink},
          }
        })
        .filter(notUndefined),
    [categories]
  )

  const channelOptions = useMemo(
    () => [
      {
        label: marketplaceLabel || 'Marketplace',
        value: 'marketplace',
      } as const,
      {
        label: acquisitionLabel || 'Acquisition',
        value: 'acquisition',
      } as const,
    ],
    [marketplaceLabel, acquisitionLabel]
  )

  useEffect(() => {
    setActiveCategory(categoryOptions.length ? categoryOptions[0].value : undefined)
    setActiveChannel(channelOptions.length ? channelOptions[0].value : undefined)
  }, [])

  if (
    (locale !== defaultLanguage && locale !== 'default') ||
    splittedParagraph.length < 2 ||
    !callToAction
  ) {
    return <div />
  }
  return (
    <section className={styles.section}>
      <div className={styles.background} style={{backgroundImage: `url(${WizardBackground})`}} />
      <div className={styles.container}>
        <div className={styles.content}>
          <Logo />
          <h2 className={styles.heading}>{heading}</h2>

          <div className={styles.wizard}>
            <div className={styles.paragraph}>
              {splittedParagraph[0]}{' '}
              <Select
                {...selectSharedOptions}
                options={categoryOptions}
                onChange={(e) => (e ? setActiveCategory(e.value) : undefined)}
                defaultValue={categoryOptions.length ? categoryOptions[0] : undefined}
              />
            </div>
            <div className={styles.paragraph}>
              {splittedParagraph[1]}{' '}
              <Select
                {...selectSharedOptions}
                options={channelOptions}
                onChange={(e) => setActiveChannel(e ? e.value : undefined)}
                defaultValue={channelOptions.length ? channelOptions[0] : undefined}
              />{' '}
              {splittedParagraph[2]}
            </div>
          </div>
          {activeCategory && activeChannel && (
            <Button
              label={callToAction}
              isSecondary
              className={styles.cta}
              link={activeCategory[activeChannel]}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default Wizard
