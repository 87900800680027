import classNames from 'classnames'

import {componentTypes} from '@/common/enums'
import {TVerticalSliderItem} from '@/common/types'
import ButtonStoryblok from '@/components/ButtonStoryblok'

import styles from './VerticalSliderItem.module.scss'

const VerticalSliderItem = ({
  index,
  heading,
  paragraph,
  button,
  isLight,
}: TVerticalSliderItem): JSX.Element => {
  return (
    <div className={classNames(styles.container, {[styles.isLight]: isLight})}>
      {index && (
        <span className={styles.index}>
          {index.toLocaleString('fr', {
            minimumIntegerDigits: 2,
          })}
        </span>
      )}
      <p className={styles.heading}>{heading}</p>
      {paragraph && <p className={styles.paragraph}>{paragraph}</p>}
      {button &&
        button.map((props, i) => {
          if (props.component !== componentTypes.BUTTON_STORYBLOK || i >= 1) return null
          return (
            <div key={props._uid} className={styles.button}>
              <ButtonStoryblok {...props} theme='default' isSecondary={!isLight} />
            </div>
          )
        })}
    </div>
  )
}

export default VerticalSliderItem
