import classNames from 'classnames'
import {useState} from 'react'

import {Circles} from '@/assets/specify'
import {componentTypes} from '@/common/enums'
import {TGridCards} from '@/common/types'
import ButtonStoryblok from '@/components/ButtonStoryblok'
import Image from '@/components/Image'
import MasonryCard from '@/components/MasonryCard'

import styles from './GridCards.module.scss'

const GridCards = ({heading, paragraph, button = [], cards = []}: TGridCards): JSX.Element => {
  const [active, setActive] = useState<number>()

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}

        {paragraph && <p className={styles.paragraph}>{paragraph}</p>}

        {cards.length > 0 && (
          <div className={styles.logosContainer}>
            {cards.map((props, index) => {
              if (props.component !== componentTypes.GRID_CARD) return null

              const {images = []} = props

              if (index !== active) return null

              return (
                <div
                  key={props._uid}
                  className={classNames(styles.logos, {[styles.isActive]: index === active})}
                >
                  {images.map((props) => {
                    if (props.component !== componentTypes.IMAGE) return null
                    return (
                      <Image
                        key={props._uid}
                        {...props}
                        wrapperClassName={styles.logoWrapper}
                        className={styles.logo}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        )}

        {button.map((props) => {
          if (props.component !== componentTypes.BUTTON_STORYBLOK) return null
          return (
            <div key={props._uid} className={styles.button}>
              <ButtonStoryblok {...props} theme='default' />
            </div>
          )
        })}
      </div>

      <div className={styles.circles}>
        <Circles className={styles.circlesSvg} />
      </div>

      <div className={styles.cards}>
        {cards.map((props, index) => {
          if (props.component !== componentTypes.GRID_CARD) return null

          const [card] = props.card || []

          if (card && card.component === componentTypes.MASONRY_CARD) {
            return (
              <div key={props._uid} className={styles.card} onMouseEnter={() => setActive(index)}>
                <MasonryCard {...card} withShadow fillHeight />
              </div>
            )
          }
        })}
      </div>
    </section>
  )
}

export default GridCards
