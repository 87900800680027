import styles from './CtaDouble.module.scss'

const Circle = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <div className={styles.circles}>
      <svg
        width='1156'
        height='1156'
        viewBox='0 0 1156 1156'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='119.215'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='181.207'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='238.43'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='326.649'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='381.488'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='462.554'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='577'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
        <circle
          className={styles.circle}
          cx='578'
          cy='578'
          r='45.3017'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='2.87 5.74'
        />
      </svg>
    </div>
  )
}

export default Circle
