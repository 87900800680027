import classNames from 'classnames'
import {useState} from 'react'

import {componentTypes} from '@/common/enums'
import {TAccordion} from '@/common/types'
import AccordionItem from '@/components/AccordionItem'

import styles from './Accordion.module.scss'

const Accordion = ({heading, items = [], isAlternative}: TAccordion): JSX.Element => {
  const [active, setActive] = useState<number>()

  return (
    <div className={classNames(styles.container, {[styles.isAlternative]: isAlternative})}>
      <section className={classNames(styles.section, {[styles.isAlternative]: isAlternative})}>
        {heading && <h2 className={styles.heading}>{heading}</h2>}

        <div className={styles.items}>
          {items.map((props, index) => {
            const isActive = active ? index === active : index === 0

            if (props.component !== componentTypes.ACCORDION_ITEM) return null
            return (
              <AccordionItem
                {...props}
                key={props._uid}
                isAlternative={isAlternative && isAlternative}
                isActive={isActive}
                handleClick={() => setActive(index)}
              />
            )
          })}
        </div>

        <div className={styles.picture}>
          {items.map((props, index) => {
            const isActive = active ? index === active : index === 0

            if (props.component !== componentTypes.ACCORDION_ITEM || !props.image) return null
            return (
              <img
                key={props._uid}
                className={classNames(styles.image, {[styles.isActive]: isActive})}
                src={props.image.filename}
                alt=''
              />
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default Accordion
