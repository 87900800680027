import classNames from 'classnames'
import {useEffect, useRef, useState} from 'react'
import Draggable from 'react-draggable'

import {componentTypes} from '@/common/enums'
import {TDraggableCardsList} from '@/common/types'
import DynamicComponent from '@/components/DynamicComponent'

import styles from './DraggableCardsList.module.scss'
import {Illustration} from './DraggableCardsListHelpers'

const DraggableCardsList = ({
  heading,
  headingTag,
  cards = [],
}: TDraggableCardsList): JSX.Element => {
  const cardsRef = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState<{x: number; y: number}>()

  const HeadingTag = headingTag || 'h2'

  function preventScroll(e: Event) {
    e.preventDefault()
  }

  function onStart() {
    if (typeof window !== 'undefined') {
      document.addEventListener('touchmove', preventScroll, {passive: false})
    }
  }

  function onStop() {
    document.removeEventListener('touchmove', preventScroll)

    if (typeof window !== 'undefined' && cardsRef.current) {
      if (cardsRef.current.firstElementChild) {
        const {left, width} = cardsRef.current.firstElementChild.getBoundingClientRect()
        if (left > window.innerWidth - width / 3) {
          setPosition({x: 0, y: 0})
        }
      }

      if (cardsRef.current.lastElementChild) {
        const {right, width} = cardsRef.current.lastElementChild.getBoundingClientRect()
        if (right < width / 3) {
          setPosition({x: 0, y: 0})
        }
      }
    }
  }

  useEffect(() => {
    if (cardsRef.current) {
      const images = cardsRef.current.querySelectorAll('img')
      images.forEach((img) => {
        img.draggable = false
      })
    }
  }, [])

  useEffect(() => {
    if (position) setPosition(undefined)
  }, [position])

  return (
    <section className={styles.section}>
      {heading && <HeadingTag className={styles.heading}>{heading}</HeadingTag>}
      <div
        className={classNames(styles.container, `items-${cards.length}`, {
          [styles.hasHeading]: !!heading,
        })}
      >
        <Illustration />
        <Draggable
          axis='x'
          onStart={onStart}
          onStop={onStop}
          defaultClassNameDragging={styles.grabbing}
          position={position}
        >
          <div className={styles.cards} ref={cardsRef}>
            {cards.map((props) => {
              if (props.component !== componentTypes.MASONRY_CARD) return null
              return (
                <div key={props._uid} className={styles.card}>
                  <DynamicComponent {...props} fillHeight />
                </div>
              )
            })}
          </div>
        </Draggable>
      </div>
    </section>
  )
}

export default DraggableCardsList
